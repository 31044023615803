import { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation, Trans } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { NominationControl } from '../../utils/nomination';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    maxWidth: '700px',
    maxHeight: '96%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '40px 20px'
  }
};

const JurorConfirmModal = ({ isOpen, onClose, region, nominees, submittedCallback }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleLongListSubmit = async () => {
    const submitNominations = await NominationControl.submitNominations(region);
    if (!submitNominations.success) {
      setError('An error has occured submitted your nomination.');
      return false;
    }
    setSubmitted(true);
    submittedCallback();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => null}
        onRequestClose={onClose}
        style={customStyles}
      >
        <button
          className="btn btn-light btn-sm btn-close position-absolute"
          type="button"
          onClick={onClose}
        ></button>

        <div className="modal-header">
          <h2 className="modal-title mt-0">
            {!submitted ? t('juror.submitNominees') : t('juror.submissionReceived')}
          </h2>
        </div>

        <div className="modal-body">
          <div className="row g-3 mb-5">
            {error &&
              <Alert variant="danger">
                <p>{error}</p>
              </Alert>
            }
            {!submitted ? (
              <>
                <p style={{ marginTop: '30px' }}><Trans i18nKey="juror.submit1" values={{ region }} /></p>
                <p>{t('juror.submit2')}</p>
                <p><strong>{t('juror.submit3')}</strong></p>
                <h3>{t('juror.yourNominees')}</h3>
                <ol style={{ margin: '5px 0 0 0', paddingLeft: '25px' }}>
                  {nominees && nominees.map((n) => (
                    <li key={n.id}>{n.artist}</li>
                  ))}
                </ol>
              </>
            ) : (
              <p style={{ marginTop: '30px' }}>{t('juror.submit4')}</p>
            )}
          </div>
        </div>

        <div className="modal-footer p-0">
          {!submitted ? (
            <>
              <button className="btn btn-lg mt-4" onClick={onClose}>
                {t('juror.cancel')}
              </button>
              <button
                className="btn btn-lg submit mt-4"
                style={{ marginLeft: '2rem' }}
                onClick={() => handleLongListSubmit()}
              >
                {t('juror.submitNominees')}
              </button>
            </>
          ) : (
            <button className="btn btn-lg mt-4" onClick={onClose}>
              {t('home.close')}
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default JurorConfirmModal;

import { ENTITY_ENDPOINT, UPLOAD_MEDIA_ENDPOINT } from './constants';

export const uploadFile = (e) => {
    const file = e;
    if (!file) return false;

    const data = new FormData();
    data.append('file-attachments-ul', file );

    return fetch(UPLOAD_MEDIA_ENDPOINT, {
        method: 'POST',
        credentials: 'include',
        body: data
    })
    .then(res => res.json())
    .then(resJson => resJson)
    .catch(error => console.error(error));
}

export const postAttachFile = (fileId, type, entity) => {

    entity.fileId = fileId;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(entity)
    };
  
    return fetch(`${ENTITY_ENDPOINT}/${type}`, options)
        .then(res => res.json())
        .then(resJson => resJson)
        .catch(error => console.error(error));
}

export const patchAttachFile = (entityId, fileId, field) => {

    const entity = {}
    entity[field] = fileId;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(entity)
    };
    
    const type = 'submission';

    return fetch(`${ENTITY_ENDPOINT}/${type}/${entityId}`, options)
        .then(res => res.json())
        .then(resJson => resJson)
        .catch(error => console.error(error));
}
import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NominationControl } from '../../../utils/nomination';
import { Alert, FloatingLabel } from 'react-bootstrap';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../../global/images/DeleteButton';
import EditButton from '../../global/EditButton';
import MediaUploadCounter from '../../global/MediaUploadModal/MediaUploadCounter';
import { handleApiErrors } from '../../../utils/error';

Modal.setAppElement('#root');

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      maxWidth: '100%',
      maxHeight: '96%',
      padding: '40px 20px'
    },
};

const MediaUploadModal = ({ nominationId, fields, button, callback, media, view }) => {

    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = React.useState(false);
    const [apiError, setApiError] = React.useState(false);
    const [removeError, setRemoveError] = React.useState(false);

    const MAXIMUM_MEDIA_COUNT = 5;
    const count = media ? media.length : 0;

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required()
            .label(t('nomination.components.title'))
            .max(120, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.components.title')).replace("{{length}}", 120))
            .nullable(),
        link: Yup.string()
            .url()
            .required()
            .label(t('nomination.components.link'))
            .max(255, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.components.link')).replace("{{length}}", 255))
            .nullable(),
        year: Yup.string().required()
            .label(t('nomination.components.year'))
            .matches(/^[0-9]+$/, "Year must be only digits")
            .test('len', 'Year must be exactly 4 characters', val => val.length === 4),
        format: Yup.string()
            .required()
            .label(t('nomination.components.format'))
            .max(60, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.components.format')).replace("{{length}}", 60))
            .nullable(),
        duration: Yup.string()
            .required()
            .label(t('nomination.components.duration'))
            .max(40, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.components.format')).replace("{{length}}", 40))
            .nullable(),
        password: Yup.string()
            .label(t('nomination.components.password'))
            .max(40, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.components.format')).replace("{{length}}", 40))
            .nullable(),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm(formOptions);

    const onSubmit = async (data) => {
        data.nominationId = nominationId;
        let result = false;
        if (!data.id) {
            delete data.id;
            result = await NominationControl.postPatchSubmission('media', data, 'POST');
        } else {
            result = await NominationControl.postPatchSubmission('media', data, 'PATCH');
        }

        const errors = handleApiErrors(result, setApiError);
        if (!errors) {
            callback();
            setModalOpen(false);
            reset();
        }
    };

  const handleModalToggle = () => {
    setRemoveError(false);
    setApiError(false);
    reset({
        title: null,
        link: null,
        year: null,
        format: null,
        duration: null,
        password: null,
        id: null
    });
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false);
    callback();
    reset();
  }

  const handleRemove = async (id) => {
    setRemoveError(false);
    let result = false;
    result = await NominationControl.postPatchEntity('DELETE', 'media',
    {},
    id)

    const errors = handleApiErrors(result, setRemoveError);
    if (!errors) {
        callback();
    }
  }

  const handleEdit = (entity) => {
    setRemoveError(false);
    setApiError(false);
    const year = entity.year ? entity.year.split("-")[0] : null;
    reset({
        title: entity.title,
        link: entity.link,
        year: year,
        format: entity.format,
        duration: entity.duration,
        password: entity.password,
        id: entity.id
    })
    setModalOpen(true);
  }

  return (
    <>
        {count < MAXIMUM_MEDIA_COUNT ? (
            <div
                onClick={handleModalToggle}
            >
                {button}
            </div>
        ) : null}
        {!view && <MediaUploadCounter media={media} /> }
        {removeError && (
                <div className="row bg-light">
                    <div className="col-12 p-4 pb-0">
                        <Alert key={`remove_Error`} variant="danger">
                            <p>{removeError}</p>
                        </Alert>
                    </div>
                </div>
        )}
        <div className="row mt-4">
        {media && media.length ? media.map((m) => (
            <div className="col col-12 col-sm-6 col-md-4 p-2" key={m.id}>
            <div className="card">
                <div className="card-body bg-white" style={{ padding: '1.5rem' }}>
                {!view &&
                  <>
                    <DeleteButton
                        action={() => handleRemove(m.id)}
                    />
                    <EditButton
                        action={() => handleEdit(m)}
                    />
                  </>
                }
                <h6 className="text-muted card-subtitle fw-light mt-1">
                    {m.year ? m.year.split("-")[0] : null}
                </h6>
                <h4>{m.title}</h4>
                <h6 className="text-muted card-subtitle mb-2 fw-light mt-3 small">
                  <i className="fa fa-link" style={{ margin: '0 0.5em 0 0', fontSize: 15 }}></i><a href={m.link} target="_blank">{m.link}</a>
                </h6>
                <h6 className="text-muted card-subtitle mb-2 fw-light mt-1 small">
                    {t('nomination.components.format')}: {m.format}
                </h6>
                <h6 className="text-muted card-subtitle mb-2 fw-light mt-1 small">
                    {t('nomination.components.duration')}: {m.duration}
                </h6>
                <h6 className="text-muted card-subtitle mb-2 fw-light mt-1 small">
                    {t('nomination.components.password')}: {m.password}
                </h6>
                </div>
            </div>
            </div>
        )) : null}
        </div>
        <Modal
            isOpen={modalOpen}
            onAfterOpen={() => null}
            onRequestClose={handleModalClose}
            style={customStyles}
        >
            <button
                className="btn btn-light btn-sm text-end rounded-circle btn-close position-absolute top-0 end-0"
                type="button"
                data-bs-target="#modal-1"
                data-bs-toggle="modal"
                onClick={handleModalClose}
            ></button>

            <div className="modal-header">
              <h3 className="modal-title mt-0">{t('nomination.step4.add')}</h3>
            </div>

                <form id="image-form">
                    {Object.keys(errors).length !== 0 && (
                        <div className="row bg-light">
                            <div className="col-12 p-4 pb-0">
                                {Object.keys(errors).map((field) => (
                                    <Alert key={field} variant="danger">
                                        <p>{errors[field].message}</p>
                                    </Alert>
                                ))}
                            </div>
                        </div>
                    )}
                    {apiError && (
                        <div className="row bg-light">
                            <div className="col-12 p-4 pb-0">
                                {apiError.map((field) => (
                                    <Alert key={field} variant="danger">
                                        <p>{field}</p>
                                    </Alert>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="col-12 p-1 mt-4">
                        <div className="input-group">
                            <FloatingLabel controlId="title" label={t('nomination.components.title')}>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('nomination.components.title')}
                                    {...register('title')}
                                    maxLength="120"
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="input-group">
                            <FloatingLabel controlId="link" label={t('nomination.components.link')}>
                                <input
                                    className="form-control"
                                    type="url"
                                    placeholder={t('nomination.components.link')}
                                    {...register('link')}
                                    maxLength="120"
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="input-group">
                            <FloatingLabel controlId="year" label={t('nomination.components.year')}>
                                <input
                                    className="form-control"
                                    placeholder={t('nomination.components.year')}
                                    type="text"
                                    pattern="\d*"
                                    maxLength="4"
                                    {...register('year')}
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="input-group">
                            <FloatingLabel controlId="format" label={t('nomination.components.format')}>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('nomination.components.format')}
                                    {...register('format')}
                                    maxLength="60"
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="input-group">
                            <FloatingLabel controlId="duration" label={t('nomination.components.duration')}>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('nomination.components.duration')}
                                    {...register('duration')}
                                    maxLength="40"
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="input-group">
                            <FloatingLabel controlId="password" label={t('nomination.components.password')}>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('nomination.components.password')}
                                    {...register('password')}
                                    maxLength="40"
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <button
                        className="btn btn-lg mt-3 mb-3"
                        form="image-form"
                        onClick={handleSubmit(onSubmit)}
                        style={{float: 'right'}}
                    >
                    {t('nomination.components.save')} <i className="fa fa-chevron-right"></i>
                    </button>
                    <div style={{clear:'right'}} />
                </form>
        </Modal>
    </>
  )
};

export default MediaUploadModal;

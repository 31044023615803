import { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    maxWidth: '600px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '40px 20px'
  }
};

const ConfirmDeleteModal = ({ isOpen, onClose, onSubmit, type }) => {
  const { t } = useTranslation();
  const [typeTxt, setTypeTxt] = useState(type);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => null}
        onRequestClose={onClose}
        style={customStyles}
      >
        <button
          className="btn btn-light btn-sm btn-close position-absolute"
          type="button"
          onClick={onClose}
        ></button>
        <p>Are you sure you want to delete this {typeTxt}?</p>
        <button className="btn btn-lg" onClick={onClose}>
          {t('nomination.components.no')}
        </button>
        <button
          className="btn btn-lg submit"
          style={{ marginLeft: '2rem' }}
          onClick={onSubmit}
        >
          {t('nomination.components.yes')}
        </button>
      </Modal>
    </>
  );
};

export default ConfirmDeleteModal;

import { useEffect, useState, useContext, createContext } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { SESSION_COOKIE_NAME, REGISTER_ENDPOINT, SIGNIN_ENDPOINT, SIGNIN_OTP_ENDPOINT, USER_ENDPOINT, LOGOUT_ENDPOINT, REQUEST_RESET_PASSWORD_ENDPOINT, CHANGE_PASSWORD_ENDPOINT, GET_REGION_PERMISSIONS_ENDPOINT } from './constants';
import { getCookie } from './cookie';


// Auth Context/Provider
const AuthContext = createContext(null);
const useAuth = () => useContext(AuthContext);

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [allRegionsOpenToJurors, setAllRegionsOpenToJurors] = useState(false);

  useEffect(() => {
    if (getCookie(SESSION_COOKIE_NAME)) {
      // Get User
      AuthProviderControl.getUser(res => {
        if (res.error) {
          setUser('guest');
          setRole(null);
        } else {
          setUser(res.email);
          setRole(res.role);
        }
      });
      // Get Permission Status
      AuthProviderControl.getRegionsAreOpenToJurors(res => {
        if (res.error) {
          setAllRegionsOpenToJurors(false);
        } else {
          setAllRegionsOpenToJurors(res.regionalSubmissionsOpen);
        }
      });
    } else {
      setUser('guest');
      setRole(null);
    }
  }, []);

  const signIn = (email, password, lang, callback) => {
    return AuthProviderControl.signIn(email, password, lang, res => {
      if (!res.error) {
        setUser(res.email);
        setRole(res.role);
      }

      callback(res);
    });
  };

  const signInOTP = (email, otpCode, lang, callback) => {
    return AuthProviderControl.signInOTP(email, otpCode, lang, res => {
      if (!res.error) {
        setUser(res.email);
        setRole(res.role);
      }
      callback(res);
    });
  }

  const signOut = () => {
    return AuthProviderControl.signOut(() => {
      setUser('guest');
      setRole(null);
    });
  };

  const register = (email, password, lang, callback) => {
    return AuthProviderControl.register(email, password, lang, res => {
      callback(res);
    });
  };

  const requestResetPassword = (email, lang, callback) => {
    return AuthProviderControl.requestResetPassword(email, lang, res => {
      callback(res);
    });
  };

  const changePassword = (data, callback) => {
    return AuthProviderControl.changePassword(data, res => {
      callback(res);
    });
  };

  const value = { user, role, signIn, signInOTP, signOut, register, requestResetPassword, changePassword, allRegionsOpenToJurors };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}


// Check if user is authenticated for routes, redirect if needed
function RequireAuth() {
  const auth = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  if (auth.user === 'guest') {
    return <Navigate to={t('routes:sign-in')} state={{ from: location }} />;
  }

  return <Outlet />;
}


// Check if user is authenticated for juror routes, redirect if needed
function RequireJurorAuth() {
  const auth = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  if (auth.user === 'guest') {
    return <Navigate to={t('routes:sign-in')} state={{ from: location }} />;
  } else if (auth.role === 'artist') {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Outlet />;
}


// Check if user is authenticated for admin routes, redirect if needed
function RequireAdminAuth() {
  const auth = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  if (auth.user === 'guest') {
    return <Navigate to={t('routes:sign-in')} state={{ from: location }} />;
  } else if (auth.role && auth.role !== 'admin') {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Outlet />;
}


// Auth API calls
const AuthProviderControl = {
  signIn(email, password, lang, callback) {
    const options = {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      body: qs.stringify({ email, password, lang })
    };

    return fetch(SIGNIN_ENDPOINT, options)
      .then(res => res.json())
      .then(resJson => callback(resJson))
      .catch(error => console.error(error));
  },
  signInOTP(email, otpCode, lang, callback) {
    const options = {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      body: qs.stringify({ email, otpCode, lang })
    };

    return fetch(SIGNIN_OTP_ENDPOINT, options)
      .then(res => res.json())
      .then(resJson => callback(resJson))
      .catch(error => console.error(error));
  },
  getUser(callback) {
    const options = {
      method: 'get',
      credentials: 'include'
    };
    return fetch(USER_ENDPOINT, options)
      .then(res => res.json())
      .then(resJson => callback(resJson))
      .catch(error => console.error(error));
  },
  signOut(callback) {
    const options = {
      method: 'get',
      credentials: 'include'
    };
    return fetch(LOGOUT_ENDPOINT, options)
      .then(res => res.json())
      .then(resJson => callback())
      .catch(error => console.error(error));
  },
  register(email, password, lang, callback) {
    const options = {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      body: qs.stringify({ email, password, lang })
    };

    return fetch(REGISTER_ENDPOINT, options)
      .then(res => res.json())
      .then(resJson => callback(resJson))
      .catch(error => console.error(error));
  },
  requestResetPassword(email, lang, callback) {
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      body: qs.stringify({ email, lang })
    };

    return fetch(REQUEST_RESET_PASSWORD_ENDPOINT, options)
      .then(res => res.json())
      .then(resJson => callback(resJson))
      .catch(error => console.error(error));
  },
  changePassword(data, callback) {
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      body: qs.stringify(data)
    };

    return fetch(CHANGE_PASSWORD_ENDPOINT, options)
      .then(res => res.json())
      .then(resJson => callback(resJson))
      .catch(error => console.error(error));
  },
  getRegionsAreOpenToJurors(callback) {
    const options = {
      method: 'get',
      credentials: 'include'
    };
    return fetch(GET_REGION_PERMISSIONS_ENDPOINT, options)
      .then(res => res.json())
      .then(resJson => callback(resJson))
      .catch(error => console.error(error));
  }
};

export { AuthProvider, RequireAuth, RequireJurorAuth, RequireAdminAuth, useAuth };

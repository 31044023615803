import '../../assets/scss/DropDown.scss';

const DropDown = ({ name, value, options = [], onChange }) => {
  return (
    <div className="dropdown-wrapper">
      <select
        name={name}
        id={name}
        className="dropdown"
        value={value}
        onChange={(e) => onChange(e)}
      >
        {options.map((opt) => <option key={`option_${opt}`} value={opt}>{opt}</option>)}
      </select>
    </div>
  );
};

export default DropDown;

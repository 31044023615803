import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FloatingLabel, Alert } from 'react-bootstrap';
import { useAuth } from '../../utils/auth';

const RegisterPage = props => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [notice, setNotice] = useState(null);
  const [hideForm, setHideForm] = useState(false);
  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('errors.requiredEmail'))
      .email(t('errors.validEmail')),
    password: Yup.string()
      .required(t('errors.requiredPassword'))
      .min(8, t('errors.validPassword')),
    confirmPassword: Yup.string()
      .required(t('errors.requiredConfirmPassword'))
      .oneOf([Yup.ref('password')], t('errors.validConfirmPassword'))
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

  useEffect(() => {
    if (auth.user !== 'guest') navigate('/');
  }, [auth, navigate]);

  const onSubmit = data => {
    auth.register(data.email, data.password, i18n.language, res => {
      if (res.error) {
        setNotice(<Alert variant="danger"><p>{res.error}</p></Alert>);
      } else {
        setNotice(<Alert variant="success"><p>{res.message}</p></Alert>);
        setHideForm(true);
      }
    });
  }

  return (
    <div className="container">
      <div className="row g-3 justify-content-center">
        <div className="col col-12 col-lg-4">
          <h1 dangerouslySetInnerHTML={{ __html: t('home.title')}} />
          <h2>{t('signUp.title')}</h2>
        </div>
      </div>

      <div className="row g-3 mb-5 justify-content-md-center">
        <div className="col col-12 col-lg-4">
          {notice}
          { errors.email &&
            <Alert variant="danger"><p>{errors.email.message}</p></Alert>
          }
          { errors.password &&
            <Alert variant="danger"><p>{errors.password.message}</p></Alert>
          }
          { errors.confirmPassword &&
            <Alert variant="danger"><p>{errors.confirmPassword.message}</p></Alert>
          }
          { !hideForm &&
            <form onSubmit={handleSubmit(onSubmit)}>
              <FloatingLabel
                controlId="email"
                label={t('forms.email')}
                className="mb-4"
              >
                <input
                  className="form-control"
                  type="email"
                  placeholder="name@example.com"
                  {...register('email')}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="password"
                label={t('forms.passwordMin')}
                className="mb-4"
              >
                <input
                  className="form-control"
                  type="password"
                  placeholder="********"
                  {...register('password')}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="confirmPassword"
                label={t('forms.passwordConfirm')}
                className="mb-4"
              >
                <input
                  className="form-control"
                  type="password"
                  placeholder="********"
                  {...register('confirmPassword')}
                />
              </FloatingLabel>

              <button
                className="btn btn-lg"
                type="submit">
                {t('signUp.title')}
                <i className="fa fa-chevron-right ml-3"></i>
              </button>
            </form>
          }
        </div>
      </div>

      <div className="row g-3 mb-5 justify-content-md-center">
        <div className="col col-12 col-lg-4 text-center">
          <Link to={t('routes:sign-in')}>{t('signIn.title')}</Link><br />
          <Link to={t('routes:forgot-password')}>{t('passwordReset.title1')}</Link>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;

import '../../assets/scss/ToggleSwitch.scss';

const ToggleSwitch = ({ name, checked, onChange, optionLabels }) => {
  return (
    <div className="toggle-switch small">
      <input
        type="checkbox"
        name={name}
        id={name}
        className="toggle-switch-checkbox"
        checked={checked}
        onChange={(e) => onChange(e)}
      />
      <label
        className="toggle-switch-label"
        htmlFor={name}
        tabIndex={1}
      >
        <span
          className="toggle-switch-inner"
          data-yes={optionLabels[0]}
          data-no={optionLabels[1]}
          tabIndex={-1}
        />
        <span className="toggle-switch-switch" tabIndex={-1} />
      </label>
    </div>
  );
};

ToggleSwitch.defaultProps = {
  optionLabels: ['Locked', 'Open']
};

export default ToggleSwitch;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en.json';
import translationFR from '../locales/fr.json';
import routesEN from '../locales/routes-en.json';
import routesFR from '../locales/routes-fr.json';

const resources = {
  en: {
    translation: translationEN,
    routes: routesEN
  },
  fr: {
    translation: translationFR,
    routes: routesFR
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: { escapeValue: false }
  });

export default i18n;

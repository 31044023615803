import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Alert } from 'react-bootstrap';
import NominationHeader from './NominationHeader';
import NominationFooter from './NominationFooter';
import ConfirmModal from '../../global/ConfirmModal';
import { NominationControl } from '../../../utils/nomination';

const NominationStep5 = () => {
  const MINIMUM_IMAGES = 5;

  const navigate = useNavigate();
  const [disclaimerData, setDisclaimerData] = useState(null);
  const [saving, setSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const onModalClose = () => setModalOpen(false);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState(false);

  const validationSchema = Yup.object().shape({
    terms: Yup.boolean().oneOf([true], t('nomination.step5.termsError'))
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(formOptions);

  const dataCallback = useCallback((disclaimerData) => {
    if (disclaimerData) {
      setDisclaimerData(disclaimerData);
    }
  }, []);

  const onSubmit = (data) => {
    const valid = onValidate();
    if (valid !== true) {
      setError(valid);

      // scroll to error
      const error = document.getElementById('error');
      error.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    }

    if (!modalOpen) {
      setModalOpen(true);
    } else {
      setSaving(true);

      NominationControl.postPatchEntity(
        'PATCH',
        'submission',
        { status: 'submitted' },
        disclaimerData.id
      )
        .then((res) => {
          setSaving(false);
          NominationControl.sendSubmissionEmail(
            disclaimerData.id,
            i18n.language
          )
            .then((res) => navigate(`${t('routes:view')}/${disclaimerData.id}/?submitted=true`))
            .catch((err) => console.error(err));
        })
        .catch((err) => {
          setSaving(false);
          console.error(err);
        });
    }
  };

  const onValidate = () => {
    const images = disclaimerData.images ? disclaimerData.images.length : 0;
    if (images < MINIMUM_IMAGES) {
      const remainingImages = MINIMUM_IMAGES - images;
      return (
        <Trans
          i18nKey="nomination.step5.validate"
          values={{ remainingImages }}
          count={remainingImages}
        />
      );
    }
    return true;
  };

  return (
    <>
      <ConfirmModal isOpen={modalOpen} onClose={onModalClose} />

      <div className="container">
        <NominationHeader step="5" data={dataCallback} />

        <form id="nomination-form" onSubmit={handleSubmit(onSubmit)}>
          {error && (
            <div id="error" className="row bg-light">
              <div className="col-12 col-lg-6 p-4 pb-0 pt-5">
                <Alert key={`submission_error`} variant="danger">
                  <p>{error}</p>
                </Alert>
              </div>
            </div>
          )}

          <div className="row bg-light">
            <div className="col-12 col-lg-8 p-lg-4 pt-lg-5 pt-5">
              <label className="form-label d-xl-flex align-items-xl-center fw-bold">
                {t('nomination.step5.heading1')}
              </label>
              <ul className="fw-light mt-3">
                {i18n.language === 'fr' ? (
                  <>
                    <li>
                      je suis citoyen(ne) canadien(ne) ou résident(e)
                      permanent(e) au Canada;
                    </li>
                    <li>
                      j’accepte les modalités du prix précisées dans les lignes
                      directrices du Prix Sobey pour les arts;
                    </li>
                    <li>
                      je certifie que les renseignements compris dans ce
                      formulaire de mise en candidature sont, à ma connaissance,
                      exacts et complets;
                    </li>
                    <li>
                      j’accepte que, advenant le cas où je serais retenu(e) pour
                      figurer sur la liste préliminaire régionale ou sur la
                      liste nationale des finalistes, toutes les images soumises
                      deviennent la propriété de la Fondation Sobey pour les
                      arts pour utilisation dans des catalogues, des sites Web,
                      des médias sociaux et tous les autres aspects
                      publicitaires et promotionnels du Prix Sobey pour les
                      arts;
                    </li>
                    <li>
                      je comprends que tout engagement par le MBAC – lié à des
                      services professionnels et droits d’auteur dans le cadre
                      de l’exposition du Prix Sobey pour les arts et de la
                      programmation connexe du MBAC – est assujetti aux
                      conditions de l’Accord-cadre entre le MBAC et la CARFAC/le
                      RAAV;
                    </li>
                    <li>
                      j’accepte de participer activement à toutes les activités
                      en direct et diffusées relatives au Prix Sobey pour les
                      arts, telles que des conférences de presse, cérémonies,
                      conférences, réceptions et autres événements connexes;
                    </li>
                    <li>
                      j’accepte, advenant le cas où je serais retenu(e) sur la
                      liste nationale des finalistes, de collaborer à une
                      entrevue préparatoire sur vidéo et de participer au
                      tournage et au montage d’une vidéo qui mettra en lumière
                      ma pratique artistique;
                    </li>
                    <li>
                      J’ACCEPTE, ADVENANT LE CAS OÙ JE SERAIS RETENU(E) SUR LA
                      LISTE PRÉLIMINAIRE OU COMME FINALISTE, DE RESPECTER LA
                      CONFIDENTIALITÉ DES RÉSULTATS JUSQU’À LA DATE DU
                      DÉVOILEMENT OFFICIEL.
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      I am a Canadian citizen or permanent resident of Canada.
                    </li>
                    <li>
                      I accept the conditions of the Award, as outlined in the
                      Sobey Art Award guidelines.
                    </li>
                    <li>
                      I certify that the information contained in this
                      nomination form is accurate and complete to the best of my
                      knowledge.
                    </li>
                    <li>
                      Should I be selected for the regional longlist and/or
                      national shortlist, I agree to authorize the Sobey Art
                      Foundation and the NGC to use these images in
                      publications, catalogues, on websites, in social media and
                      in all other aspects of publicity and promotion for the
                      Sobey Art Award.
                    </li>
                    <li>
                      I understand that all engagements by the NGC — regarding
                      professional services and copyright within the context of
                      the Sobey Art Award exhibition and related NGC programming
                      — fall under the Terms of the Scale Agreement between the
                      NGC and CARFAC-RAAV.
                    </li>
                    <li>
                      I agree to fully participate in all SAA live and broadcast
                      activities, such as media conferences, ceremonies,
                      lectures, receptions, and other related events.
                    </li>
                    <li>
                      I agree, as a shortlisted nominee, to collaborate in a
                      preparatory video interview, and to participate in the
                      filming, editing and post-production of a video profiling
                      my artistic practice.
                    </li>
                    <li>
                      IF I AM LONGLISTED and/or SHORTLISTED, I AGREE TO KEEP THE
                      RESULTS COMPLETELY CONFIDENTIALUNTIL THE DATE OF THE
                      PUBLIC ANNOUNCEMENT.
                    </li>
                  </>
                )}
              </ul>
              <div className="form-check p-3 bg-secondary rounded conditions">
                <input
                  {...register('terms')}
                  className={`form-check-input border-primary ${
                    errors.terms ? 'is-invalid' : ''
                  }`}
                  style={{ marginLeft: '0', marginRight: '0.5em' }}
                  type="checkbox"
                />
                <label
                  className="form-check-label text-uppercase"
                  htmlFor="terms"
                >
                  {t('nomination.step5.terms')}
                </label>
                <div className="invalid-feedback">
                  <p>{errors.terms?.message}</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 pt-5">
              <div className="vstack">
                <label className="form-label d-xl-flex align-items-xl-center fw-bold mt-3">
                  {t('nomination.step5.heading2')}
                </label>
              </div>
              <p className="small fw-light">
                {t('nomination.step5.disclaimer')}
              </p>
            </div>
          </div>
        </form>
      </div>

      <NominationFooter saving={saving} final={true} />
    </>
  );
};

export default NominationStep5;

export const handleApiErrors = (res, callback) => {

    if (res.result) {
        if (res.result.length) {
            const errors = [];
            res.result.map((row) => {
                if (row.error) {
                    errors.push(row.debug);
                }
            });
    
            if (errors.length) {
                callback(errors)
                return true;
            }
            return false;
        }
    }
    if (res.error) {
        callback([res.error])
        return true;
    }
    return false;
}
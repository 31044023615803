import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    maxWidth: '1000px',
    maxHeight: '96%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '40px 20px'
  }
};

const GuidelinesModal = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => null}
        onRequestClose={onClose}
        style={customStyles}
      >
        <button
          className="btn btn-light btn-sm btn-close position-absolute"
          type="button"
          onClick={onClose}
        ></button>

        <div className="modal-header">
          <h2 className="modal-title mt-0">
            {t('nomination.footer.nominationGuidelines')}
          </h2>
        </div>

        <div className="modal-body">
          <div className="row g-3 mb-5">
            {i18n.language === 'fr' ? (
              <>
                <h3 style={{textTransform: 'uppercase'}}>Critères d’admissibilité des candidatures</h3>
              <p>Le Prix Sobey pour les arts est ouvert aux artistes et aux collectifs. <br />
Les personnes mises en candidature doivent répondre aux critères suivants :</p>
              <ul>
                <li>avoir la citoyenneté canadienne ou un statut de résidence permanente au Canada ;</li>
                <li>
                être engagées de façon continue dans leur pratique artistique ; et
                </li>
                <li>
                avoir reçu la reconnaissance de leurs pairs, de critiques ou de conservateur·trice·s.
                </li>
              </ul>

              <h3 style={{textTransform: 'uppercase'}}>Admissibilité des personnes proposant les candidatures</h3>
              <p>
              Les personnes qui proposent les candidatures doivent répondre à un ou plusieurs des critères
suivants :
              </p>
              <ul>
                <li>
                assumer la direction d’un musée, d’une galerie d’art publique, d’un organisme artistique
professionnel ou d’un centre d’art autogéré canadien, ou être membre de l’équipe de
conservation, ou du conseil d’administration de telles institutions ;
                </li>
                <li>
                administrer ou diriger un programme de beaux-arts dans une université ou un collège
d’art canadien, ou enseigner dans un tel établissement ;
                </li>
                <li>
                être marchand·e d’art privé·e canadien·ne en arts visuels ou médiatiques ;
                </li>
                <li>
                être critique, auteur·rice ou commissaire en arts visuels ou médiatiques et avoir une
pratique indépendante et établie ; ou
                </li>
                <li>être artiste en arts visuels et avoir une carrière établie à ce titre.</li>
              </ul>

              <h2>Lignes directrices pour les dépôts de candidature</h2>

<p>
Le dossier de candidature doit comprendre tous les éléments suivants :
</p>
<ul>
  <li>
  le formulaire de mise en candidature rempli (en ligne) ;
  </li>
  <li>
  une photographie du visage de l’artiste, dont le côté plus long doit faire au moins
  2000 pixels (libre de droits pour usage promotionnel non commercial par la Fondation
  Sobey pour les arts, le MBAC et des médias tiers dans la presse écrite, sur le Web et
  dans les médias sociaux, à l’appui du Prix Sobey pour les arts 2024). S’il s’agit d’un collectif, fournir une photographie du groupe.
  </li>
  <li>le curriculum vitae de l’artiste ou du collectif ;</li>
  <li>
  une biographie de 100 mots en français ou en anglais (noter que la biographie sera
traduite dans l’autre langue officielle) ;
  </li>
  <li>
  un maximum de 10 images numériques des œuvres les plus récentes de l’artiste ou du
collectif, dont au moins 5 images numériques libres de droits pour usage promotionnel
non commercial par la Fondation Sobey pour les arts, le MBAC et des médias tiers dans
la presse écrite, sur le Web et dans les médias sociaux, à l’appui du Prix Sobey pour les
arts 2024 ; un maximum de 5 œuvres audio/vidéo (hébergées en ligne) peuvent aussi
être fournies en supplément ;
  </li>
  <li>
  une lettre d’accompagnement d’une page ou deux rédigée par la personne proposant la
candidature, soulignant les réalisations les plus importantes de l’artiste ou du collectif et
justifiant la mise en candidature. La lettre doit expliquer :
  </li>
  <li style={{ marginLeft: '1rem' }}>
  pourquoi l’artiste ou le collectif devrait être considéré pour le Prix Sobey pour les
  arts. Les personnes mises en candidature sont admissibles à tous les stades de
  leur carrière. En recensant leurs réalisations, il convient d’expliquer en quoi leur
  pratique se distingue parmi celles de leurs pairs dans le champ de la production
  artistique contemporaine au Canada. À mi-carrière, l’accent peut être mis sur les
  raisons pour lesquelles l’artiste ou le collectif mérite une reconnaissance plus
  large, tant au Canada qu’à l’étranger.
  </li>
  <li style={{ marginLeft: '1rem' }}>
  en quoi le moment présent constitue une étape critique dans la pratique de
l’artiste ou du collectif. Pour ceux qui sont déjà établis ou dont la carrière est
déjà longue, il peut s’agir d’un tournant, d’une nouvelle direction ou de la
reconnaissance d’une constance dans l’ensemble de l’œuvre, qui n’a pas encore
reçu l’attention qu’elle mérite.
  </li>
  <li style={{ marginLeft: '1rem' }}>
  comment la pratique de l’artiste ou du collectif résonne hors des frontières du
pays ou est susceptible de le faire. Cet aspect est pertinent dans la mesure où le
Prix Sobey pour les arts est un prix d’art contemporain d’importance majeure et
où son comité de sélection comprend un éminent juré international.
  </li>
</ul>

<h3 style={{textTransform: "uppercase"}}>Lignes directrices relatives à la soumission des images
numériques</h3>
            <ul>
              <li>
              Les images soumises doivent être grandes, de haute qualité et d’une résolution
minimale dont le côté le plus long doit faire au moins 2000 pixels (prêtes pour la presse
et l’impression).
              </li>
              <li>
              Se limiter à un maximum de 10 images du travail le plus récent de l’artiste ou du
collectif.
              </li>
              <li>
              Les fichiers doivent être compatibles PC et MAC (.jpg seulement).
              </li>
              <li>
              Ne pas ajouter de caractères spéciaux, symboles, guillemets, espaces, etc. dans le nom du fichier.
              </li>
            </ul>

            <h3 style={{textTransform: "uppercase"}}>
            Lignes directrices relatives à la soumission du matériel audio ou
vidéo supplémentaire
            </h3>
            <ul>
              <li>
              Se limiter à 5 œuvres audio ou vidéo.
              </li>
              <li>
              Les fichiers audio ou vidéo doivent être disponibles en ligne (dans une zone sécurisée
d’un site Web ou sur YouTube ou Vimeo).
              </li>
              <li>Les fichiers ZIP ne seront pas acceptés.</li>
            </ul>

            <p>
            Veuillez noter qu’une seule candidature par artiste ou collectif sera acceptée.
            </p>

            <p>
            Pour toute question relative au processus de candidature, communiquez avec <a href="mailto:sobey@beaux-arts.ca">sobey@beaux-arts.ca</a>
            </p>

            <p>
            Les renseignements personnels contenus dans ce formulaire seront conservés dans un système
sécurisé et seront communiqués uniquement au comité de gestion du Prix Sobey pour les
arts 2024. Pour en savoir plus sur la gestion des renseignements personnels au Musée des
beaux-arts du Canada, veuillez consulter beaux-arts.ca.

Pour savoir comment accéder aux renseignements personnels que nous conservons à votre
sujet afin de les corriger ou d’y apporter des ajouts, ou pour formuler une plainte sur leur
gestion, veuillez communiquer avec l’agent de la protection de la vie privée du Musée par
courriel à <a href="mailto:vieprivee@beaux-arts.ca">vieprivee@beaux-arts.ca</a> ou par la poste à l’adresse suivante : Agent de la protection
de la vie privée, Musée des beaux-arts du Canada, 380, promenade Sussex, Ottawa (Ontario) K1N 9N4.
            </p>
              </>
            ) : (
              <>
               <h3>ELIGIBILITY CRITERIA FOR ARTISTS</h3>
              <p>The Sobey Art Award is open to individual artists and collectives. To be eligible, nominees must:</p>
              <ul>
                <li>
                Be Canadian citizens or permanent residents of Canada;
                </li>
                <li>
                Have shown a commitment to artistic practice; and
                </li>
                <li>
                Have received recognition from peers, critics and/or curators.
                </li>
              </ul>

              <h3>ELIGIBILITY CRITERIA FOR NOMINATORS</h3>
              <p>Nominators must be one or more of the following:</p>
              <ul>
                <li>
                A director, curator or member of the Board of Directors of a Canadian museum, public art gallery, professional arts organization or artist-run-centre;
                </li>
                <li>
                A Dean, Chair or other professional of a Fine Arts program at a Canadian university or art college;
                </li>
                <li>A private Canadian dealer in visual or media arts;</li>
                <li>
                An established visual or media arts critic, writer or independent curator; and/or
                </li>
                <li>An established artist.</li>
              </ul>

              <h2>Application guidelines</h2>

            <p>
            The completed application package must include all of the following:
            </p>
            <ul>
              <li>Completed nomination form (filled online);</li>
              <li>
              Artist’s headshot, where the longest side of the image is at least 2,000 pixels (with full
              authorization for non-commercial, promotional use by the Sobey Art Foundation, the
              NGC, and third-party media in print, web and social media, in support of the 2024 Sobey
              Art Award). In the case of a collective, please include a
              photo of the full group;
              </li>
              <li>Artist’s/collective’s CV;</li>
              <li>
              100-word biography in English or French (please note that the biography will be
              translated into the other official language);
              </li>
              <li>
              Maximum of 10 digital images of the nominee’s most recent work, including a minimum
of 5 digital images that are fully authorized for non-commercial, promotional use by the

Sobey Art Foundation, the NGC, and third-party media in print, web and social media, in
support of the 2024 Sobey Art Award. Nominees may also provide a maximum of 5
audio/video works (hosted online);
              </li>
              <li>1 to 2-page reference letter by the nominator, detailing the nominee’s most significant
achievements and reasons for the nomination. The letter must outline:
              </li>
                <li style={{ marginLeft: '1rem' }}>
                Why the nominee should be considered as a candidate for the Sobey Art Award.
                Artists at all levels of their career are eligible for consideration. In listing the
                nominee’s accomplishments, attention should be paid to explaining how their
                practice distinguishes itself among that of their peers in the field of
                contemporary artistic production in Canada. If the nominee is in mid-career,
                emphasis may be placed on why they deserve wider recognition both in and
                outside of Canada;  
                </li>
                <li style={{ marginLeft: '1rem' }}>
                How the present moment marks a critical juncture within the nominee’s
practice. For more established artists and/or those with long careers, this might
entail a turning point, a new direction, or recognizing consistency within an
overall oeuvre that has yet to receive proper attention; and
                </li>
                <li style={{ marginLeft: '1rem' }}>
                How the nominee’s practice may, should or does resonate outside of the
country. This is pertinent as the Sobey Art Award is Canada’s pre-eminent prize
for contemporary art and its selection committee includes a prominent
international juror.
                </li>
            </ul>

            <h3>DIGITAL-IMAGE SUBMISSION GUIDELINES</h3>
            <ul>
              <li>
              Large, high-quality images, where the longest side of the image is at least 2,000 pixels
(press- and print-ready).
              </li>
              <li>
              No more than 10 images of the nominee’s most recent work will be accepted.
              </li>
              <li>Files must be PC and Mac compatible (.jpg only).</li>
              <li>
              Do not include special characters, symbols, quotation marks, spaces, etc., in the file name.
              </li>
            </ul>

            <h3>
              ADDITIONAL SUPPORT MATERIAL (VIDEO OR AUDIO) SUBMISSION GUIDELINES
            </h3>
            <ul>
              <li>
              Up to 5 video or audio works will be accepted.
              </li>
              <li>
              Video files must be made available online (through a secure area of your website, or via
YouTube or Vimeo).
              </li>
              <li>ZIP files will not be accepted.</li>
            </ul>

            <p>
            Please note that only one submission per artist or collective will be accepted.
            </p>

            <p>
            In the event that you have questions about the application process, contact <a href="mailto:sobey@gallery.ca">sobey@gallery.ca</a>
            </p>

            <p>
            Personal information collected via this form will be stored in a secure system and will only be
            shared with the 2024 Sobey Art Award Management Committee. For more on how personal
            information is managed at the National Gallery of Canada, please consult gallery.ca.

            To obtain additional information on how to access, correct or add to your personal information,
            or to lodge a complaint, please contact the Privacy Officer by email at: <a href="mailto:privacy@gallery.ca">privacy@gallery.ca</a>, or by
            mail at: Privacy Officer, National Gallery of Canada, 380 Sussex Dr., Ottawa ON K1N 9N4.
            </p>
              </>
            )}
          </div>
        </div>

        <div className="modal-footer p-0">
          <button className="btn btn-lg m-0 mt-4" onClick={onClose}>
            {t('home.close')}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default GuidelinesModal;

import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import ArtistCollective from '../../global/Artist/ArtistCollective';
import ImageUploadModal from '../../global/images/ImageUploadModal';
import MediaUploadModal from '../../global/MediaUploadModal/MediaUploadModal'
import { NominationControl } from '../../../utils/nomination';

const NominationViewPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [urlParams] = useSearchParams();
  const submitted = urlParams.get('submitted');
  const [notice, setNotice] = useState(null);
  const { t, i18n } = useTranslation();

  const [artistData, setArtistData] = useState(null);
  const [nominatorData, setNominatorData] = useState(null);
  const [digitalImagesData, setDigitalImagesData] = useState(null);
  const [mediaWorksData, setMediaWorksData] = useState(null);

  useEffect(() => {
    if (submitted)
      setNotice(
        <Alert variant="success">
          <p>{t('nomination.view.thankYou')}</p>
        </Alert>
      );
  }, [submitted, t]);

  useEffect(() => {
    NominationControl.getSubmission(id).then(({ submission }) => {
      if (!submission || submission.status === 'in progress' || submission.status === null) {
        navigate('/');
      } else if (submission.id) {
        const { primary, secondary } = getArtists(submission);

        setArtistData({
          data: primary,
          collective: secondary,
          collectiveName: submission.collective_name,
          cv: submission.cv,
          bio: submission.bio,
          headshot: submission.headshot
        });

        setNominatorData({
          nominator_first_name: submission.nominator_first_name,
          nominator_last_name: submission.nominator_last_name,
          category: submission.category,
          category_organization: submission.category_organization,
          letter: submission.letter
        });

        setDigitalImagesData({
          images: submission.images
        });

        setMediaWorksData({
          media: submission.mediaWork
        });
      }
    });
  }, []);

  return (
    <div className="container">
      <div className="row">
    		<div className="col-12">
    			<h1 className="title">View Nomination Submission</h1>
    		</div>
    	</div>

    	<div className="row mb-5">
    		<div className="col-12">
          {notice}
    		</div>
    	</div>

      {artistData?.data &&
        <>
          <h2>{t('nomination.header.step1')}</h2>

          <div className="row mb-5">
            <div className="col-12 col-md-6">
              <label className="form-label d-xl-flex align-items-xl-center fw-bold">
                {t('nomination.step1.heading1')}
              </label>
              <h5 className="text-muted mb-2 fw-light mt-1">
              {artistData.data.first_name} {artistData.data.last_name}
              </h5>
              <h6 className="text-muted mb-2 fw-bold mt-1">{`${t('nomination.step1.heading3')}:`}</h6>
              <h6 className="text-muted mb-2 fw-light mt-1">
              {artistData.data.address_2} {artistData.data.address_1} {artistData.data.address_rr}
              </h6>
              <h6 className="text-muted mb-2 fw-light mt-1">
              {artistData.data.address_city} {artistData.data.address_province}
              </h6>
              <h6 className="text-muted mb-2 fw-light mt-1">
              {artistData.data.address_pc}
              </h6>
              <h6 className="text-muted mb-2 fw-light mt-1">
                <span className="fw-bold">{t('nomination.step1.region.title')}:</span> {artistData.data.region}
              </h6>
              <h6 className="text-muted mb-2 fw-light mt-1">
                <span className="fw-bold">{t('forms.email')}:</span> {artistData.data.email}
              </h6>
              <h6 className="text-muted mb-2 fw-light mt-1">
                <span className="fw-bold">{t('nomination.step1.phone')}:</span> {artistData.data.phone}
              </h6>
              {artistData.data.alternate_phone &&
                <h6 className="text-muted mb-2 fw-light mt-1">
                  <span className="fw-bold">{t('nomination.step1.phone2')}:</span> {artistData.data.alternate_phone}
                </h6>
              }
            </div>
            {artistData?.headshot?.path &&
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <div className="vstack">
                  <label className="form-label fw-bold">
                    {t('nomination.step1.heading7')}
                  </label>
                </div>
                <div className="card col-12">
                  <div className="card-body text-center bg-white">
                    <img src={artistData.headshot.path} alt="" />
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                      {artistData.headshot.name}
                    </h6>
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                      {t('nomination.step1.credit')}: {artistData?.data?.headshotCredit}
                    </h6>
                  </div>
                </div>
              </div>
            }
          </div>
        </>
      }

      {artistData?.collective &&
        <div className="row mb-5">
          <div className="col-12">
            <label className="form-label d-xl-flex align-items-xl-center fw-bold">
              {t('nomination.step1.heading4')}
            </label>
            <br />
            <label className="d-xl-flex align-items-xl-center">
              {t('nomination.step1.collectiveName')}: {artistData.collectiveName}
            </label>
            <ArtistCollective collective={artistData.collective} view={true} />
          </div>
        </div>
      }

      <div className="row mb-5">
        {artistData?.bio?.name &&
          <div className="col-12 col-md-6">
            <div className="vstack">
              <label className="form-label fw-bold">
                {artistData?.collective.length > 1 && i18n.language !== 'fr'
                  ? t('nomination.step1.collective')
                  : ''}{' '}
                {t('nomination.step1.heading5')}{' '}
                {artistData?.collective.length > 1 && i18n.language === 'fr'
                  ? t('nomination.step1.collective')
                  : ''}
              </label>
            </div>
            <div className="card col-12">
              <div className="card-body bg-white">
                <i className="fa fa-file-o text-center"></i>
                <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                  {artistData?.bio?.name}
                </h6>
              </div>
            </div>
          </div>
        }

        {artistData?.cv?.name &&
          <div className="col-12 col-md-6">
            <div className="vstack">
              <label className="form-label fw-bold">
                {artistData?.collective.length > 1 && i18n.language !== 'fr'
                  ? t('nomination.step1.collective')
                  : ''}{' '}
                {t('nomination.step1.heading6')}{' '}
                {artistData?.collective.length > 1 && i18n.language === 'fr'
                  ? t('nomination.step1.collective')
                  : ''}
              </label>
            </div>
            <div className="card col-12">
              <div className="card-body bg-white">
                <i className="fa fa-file-o text-center"></i>
                <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                  {artistData?.cv?.name}
                </h6>
              </div>
            </div>
          </div>
        }
      </div>

      {nominatorData &&
        <>
          <h2>{t('nomination.header.step2')}</h2>

          <div className="row mb-5">
            <div className="col-12 col-md-4">
              <h5 className="text-muted mb-2 fw-light mt-1">
                {nominatorData.nominator_first_name} {nominatorData.nominator_last_name}
              </h5>
              <h6 className="text-muted mb-2 fw-light mt-1">
                <span className="fw-bold">{t('nomination.step2.heading3')}:</span> {nominatorData.category}
              </h6>
              {nominatorData.category_organization &&
                <h6 className="text-muted mb-2 fw-light mt-1">
                  <span className="fw-bold">{t('nomination.step2.category.placeholder')}:</span> {nominatorData.category_organization}
                </h6>
              }
            </div>
          </div>

          {nominatorData?.letter?.name &&
            <div className="row mb-5">
              <div className="col-12 col-md-4">
                <label className="form-label fw-bold">
                  {t('nomination.step2.heading2')}
                </label>

                <div className="card col-12">
                  <div className="card-body bg-white">
                    <i className="fa fa-file-o text-center"></i>
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                      {nominatorData.letter.name}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      }

      {digitalImagesData?.images &&
        <>
          <h2>{t('nomination.header.step3')}</h2>
          <ImageUploadModal images={digitalImagesData.images} view={true} />
        </>
      }

      {mediaWorksData?.media &&
        <>
          <h2>{t('nomination.header.step4')}</h2>
          <MediaUploadModal media={mediaWorksData.media} view={true} />
        </>
      }

    </div>
  );
}

export default NominationViewPage;


const getArtists = (submission) => {
  if (!Array.isArray(submission.artists)) {
    return { primary: {}, secondary: [] };
  }
  // sort by date created, just in case
  const sorted = submission.artists.sort(function(a,b){
    return new Date(b.createdAt) - new Date(a.createdAt);
  }).reverse();
  // split into primary and secondary
  const hasSecondary = sorted.length > 1;
  const primary = {...sorted.shift()};
  const secondary = hasSecondary ? sorted : [];

  return { primary, secondary }
}

import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, usePagination } from 'react-table';
import toast, { Toaster } from 'react-hot-toast';
import ConfirmDeleteModal from '../../global/ConfirmDeleteModal';
import { NominationControl } from '../../../utils/nomination';
import { AdminControl } from '../../../utils/admin';
import { FloatingLabel, Alert } from 'react-bootstrap';

const AdminUserManagement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [users, setUsers] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteID, setDeleteID] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const onModalClose = () => setModalOpen(false);

  useEffect(() => {
    (async () => {
      let getUsers = await AdminControl.getEntity(
        'User',
        'id,firstName,lastName,email,region,role,createdAt'
      );

      // For development
      // getUsers = getUsers.filter((i) => i.email == "john.lofthouse2@u7solutions.com");

      if (!getUsers.error) {
        setUsers(getUsers);
        setFilteredUsers(getUsers);
      }
    })();
  }, []);

  const onRoleChange = async (value, userID) => {
    const updateRole = await NominationControl.postPatchEntity(
      'PATCH',
      'user',
      {
        role: value
      },
      userID
    );

    if (updateRole.success) {
      toast.success('User role successfully updated');
    } else {
      toast.error('There was an error with updating the user role');
    }
  };

  const handleConfirmDelete = (id) => {
    setModalOpen(true);
    setDeleteID(id);
  };

  const handleDeleteUser = async () => {
    const { success } = await NominationControl.deleteEntity('user', deleteID);
    setModalOpen(false);
    if (success) setUsers(users.filter((u) => u.id !== deleteID));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName'
      },
      {
        Header: 'Last Name',
        accessor: 'lastName'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Region',
        accessor: 'region'
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: (e) => {
          const userID = e.cell.row.original.id;

          return (
            <select
              name="role"
              id="role"
              className="form-select"
              defaultValue={e.value}
              onChange={(e) => onRoleChange(e.target.value, userID)}
            >
              <option value="artist">Artist</option>
              <option value="regional-juror">Regional Juror</option>
              <option value="international-juror">International Juror</option>
              <option value="collaborator">Collaborator</option>
              <option value="head-juror">Head Juror</option>
              <option value="admin">Admin</option>
            </select>
          );
        }
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: (e) => {
          const date = new Date(e.value);
          const formatDate = date.toLocaleString('default', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          });
          return <div>{formatDate}</div>;
        }
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        Cell: (e) => {
          const userID = e.cell.row.original.id;

          return (
            <div className="position">
              <button
                className="btn btn-lg positionBtn"
                onClick={() => {
                  navigate(`/admin-user-management/edit/${userID}`);
                }}
              >
                {t('home.edit')}
              </button>
              <button
                className="btn btn-lg positionBtn"
                onClick={(e) => {
                  e.stopPropagation();
                  handleConfirmDelete(userID);
                }}
              >
                {t('home.delete')}
              </button>
            </div>
          );
        }
      }
    ],
    [t]
  );
  
  const handleFilterUsers = (e) => {
    setSearchTerm(e.target.value);
  }

  useEffect(() => {

    if (users && searchTerm != '') {
      const lowerSearchTerm = searchTerm.toLowerCase();
      let filteredUsers = [];

      if (lowerSearchTerm.startsWith('role:')) {
          const role = lowerSearchTerm.slice(5).trim().replace(" ", "-");
          filteredUsers = users.filter(user => user.role.toLowerCase().includes(role));
      } else if (lowerSearchTerm.startsWith('before:')) {
          const dateStr = lowerSearchTerm.slice(7);
          const date = new Date(dateStr);
          filteredUsers = users.filter(user => new Date(user.createdAt) < date);
      } else if (lowerSearchTerm.startsWith('after:')) {
          const dateStr = lowerSearchTerm.slice(6);
          const date = new Date(dateStr);
          filteredUsers = users.filter(user => new Date(user.createdAt) > date);
      } else {
          const searchTerms = lowerSearchTerm.split(' ');
          filteredUsers = users.filter(user =>
              searchTerms.every(term =>
                  user?.firstName?.toLowerCase().includes(term) ||
                  user?.lastName?.toLowerCase().includes(term) ||
                  user?.email?.toLowerCase().includes(term)
              )
          );
      }

      setFilteredUsers(filteredUsers);
    }
  }, [users, searchTerm]);


  function Table({ columns, data }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize }
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 }
      },
      useSortBy,
      usePagination
    );

    return (
      <>
        <table {...getTableProps()} className="userManagement">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span className={`${column.isSorted ? 'active' : ''}`}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' ▼'
                          : ' ▲'
                        : !column.disableSortBy
                        ? ' ▲'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <div>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
          </div>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <span>
            Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            className="form-select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }

  return (
    <div id="Admin-User-Management" className="container">
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 3000
        }}
      />

      <ConfirmDeleteModal
        isOpen={modalOpen}
        onClose={onModalClose}
        onSubmit={handleDeleteUser}
        type="user"
      />

      <div className="row">
        <div className="col-lg-6 pt-3">
          <h1 className="mb-5">User Management</h1>
        </div>
        <div className="col-lg-6 pt-3">
          <FloatingLabel
            controlId="search"
            label={`Search users by name or email`}
            className="mr-1"
          >
            <input
              className="form-control"
              type="text"
              value={searchTerm}
              onChange={handleFilterUsers}
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row pb-5">
        <div className="col-12 mb-5">
          <button
            className="btn btn-lg"
            onClick={() => navigate('/admin-user-management/create')}
          >
            Create User
          </button>
        </div>

        <div className="col-12">
          {!filteredUsers ? (
            <p>Loading...</p>
          ) : (
            <Table columns={columns} data={filteredUsers} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminUserManagement;

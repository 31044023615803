import React from 'react';
import { useTranslation } from 'react-i18next';
import FileUploadField  from '../FileUploadField';
import { NominationControl } from '../../../utils/nomination';

const ImageUpload = ({ nominationId, fields, onComplete, accepts = ".jpg, .jpeg", onValidate, getFileAttachment }) => {

  const { t } = useTranslation();

  const fileRef = React.useRef(null);
  const [fileResponse, setFileResponse] = React.useState(null);

  const handleImageUpload = async (e) => {
    setFileResponse(null);

    if (e?.name) {
      setFileResponse(e?.name);
    }

    onComplete(e);
    return { file: { attachment: { id: true }}};
  }

  /**
   *   const handleImageUpload = async (e) => {
    setFileResponse(null);

    const entity = {
      nominationId,
    }

    const result = await NominationControl.uploadAndAttachImage(e, entity);
    onComplete(result);

    if (e?.name) {
      setFileResponse(e?.name);
    }

    return result;
  }
   */

  const handleButtonPress = () => {
      fileRef.current.click();
  }

  return (
    <div
        onClick={handleButtonPress}
    >
        <button className="btn btn-light mt-4 mb-3 d-block" type="button">
          <i
            className="fa fa-cloud-upload"
            style={{ marginRight: '10px' }}
          ></i>
          {t('nomination.components.select')}
        </button>
        <FileUploadField
            ref={fileRef}
            onUploadEvent={handleImageUpload}
            accepts={accepts}
            onValidate={onValidate}
            ignoreError={true}
            ignoreSuccess={true}
        />
        {fileResponse && (
          <p>{fileResponse}</p>
        )}
    </div>
  )
};

export default ImageUpload;

import { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { differenceInDays } from 'date-fns';
import JurorConfirmModal from '../../global/JurorConfirmModal';
import { NominationControl } from '../../../utils/nomination';
import { AdminControl } from '../../../utils/admin';
import { useAuth } from '../../../utils/auth';

import Check from '../../../assets/img/check.svg';
import 'react-tabs/style/react-tabs.css';
import '../../../assets/scss/JurorDashboard.scss';

const VOTING_COMPLETE = 'voting complete';
const VOTING_INPROGRESS = 'in progress';

const JurorDashboard = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [error, setError] = useState(null);
  const [region, setRegion] = useState(null);
  const [nominations, setNominations] = useState(null);
  const [considerations, setConsiderations] = useState(null);
  const [longList, setLongList] = useState(null);
  const [shortList, setShortList] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const onModalToggle = () => setModalOpen(!modalOpen);
  const submittedCallback = () => setVotingStatus(VOTING_COMPLETE);

  const [allowSubmit, setAllowSubmit] = useState(false);
  const [votingStatus, setVotingStatus] = useState(null);
  const [longListOpen, setLongListOpen] = useState(null);
  const [shortListOpen, setShortListOpen] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: t('juror.date'),
        accessor: 'createdAt',
        Cell: (e) => {
          const date = new Date(e.value);
          const formatDate = date.toLocaleString('default', {
            month: 'short',
            day: 'numeric'
          });
          return <div>{formatDate}</div>;
        }
      },
      {
        Header: t('nomination.header.step1'),
        accessor: 'artist'
      },
      {
        Header: t('juror.rating'),
        accessor: 'score',
        Cell: (e) => {
          const nominationID = e.cell.row.original.id;

          return (
            <div className="rating">
              <span
                className={`${e.value > 0 ? 'active' : ''} ratingCircle`}
                onClick={() =>
                  handleNominationChange('score', 1, nominationID)
                }
              ></span>
              <span
                className={`${e.value > 1 ? 'active' : ''} ratingCircle`}
                onClick={() =>
                  handleNominationChange('score', 2, nominationID)
                }
              ></span>
              <span
                className={`${e.value > 2 ? 'active' : ''} ratingCircle`}
                onClick={() =>
                  handleNominationChange('score', 3, nominationID)
                }
              ></span>
              <span
                className={`${e.value > 3 ? 'active' : ''} ratingCircle`}
                onClick={() =>
                  handleNominationChange('score', 4, nominationID)
                }
              ></span>
              <span
                className={`${e.value > 4 ? 'active' : ''} ratingCircle`}
                onClick={() =>
                  handleNominationChange('score', 5, nominationID)
                }
              ></span>
            </div>
          );
        }
      },
      {
        Header: t('juror.position'),
        accessor: 'status',
        disableSortBy: true,
        Cell: (e) => {
          const nominationID = e.cell.row.original.id;
          const { shortListOpen, longListOpen, longList, shortList } = e;
          
          let shortListDisabled = !shortListOpen;
          if (shortListOpen === false || (shortList?.length && shortList.length < 1)) {
            shortListDisabled = 1;
          }
          if (e.cell.row.original.status === 'short-list' || ['head-juror', 'admin'].includes(auth?.role)) {
            shortListDisabled = 0;
          }
          let longListDisabled = !longListOpen;
          if (longListOpen === false || (longList?.length && longList.length < 5)) {
            longListDisabled = 1;
          }
          if (e.cell.row.original.status === 'long-list' || ['head-juror', 'admin'].includes(auth?.role)) {
            longListDisabled = 0;
          }
          return (
            <div className="position">
              <button
                className={`${
                  e.value === 'dismiss' ? 'active' : ''
                } btn btn-lg positionBtn`}
                onClick={() =>
                  handleNominationChangeToggle(
                    e.cell.row.original.status, 'status', 'dismiss', e.cell.row.original.status_history, nominationID
                  )
                }
              >
                {e.cell.row.original.status === 'dismiss' ? `${t('juror.undo')} ${ t('juror.dismiss')}` : t('juror.dismiss')}
              </button>
              <button
                className={`${
                  e.value === 'consider' ? 'active' : ''
                } btn btn-lg positionBtn`}
                onClick={() =>
                  handleNominationChangeToggle(
                    e.cell.row.original.status, 'status', 'consider', e.cell.row.original.status_history, nominationID
                  )
                }
              >
                {e.cell.row.original.status === 'consider' ? `${t('juror.undo')} ${ t('juror.consider')}` : t('juror.consider')}
              </button>
              <button
                className={`${
                  e.value === 'long-list' ? 'active' : ''
                } btn btn-lg positionBtn`}
                disabled={longListDisabled}
                title={(longList?.length && longList.length >= 5) ? t('juror.error1') : ''}
                onClick={() =>
                  handleNominationChangeToggle(
                    e.cell.row.original.status, 'status', 'long-list', e.cell.row.original.status_history, nominationID
                  )
                }
              >
                {e.cell.row.original.status === 'long-list' ? `${t('juror.undo')} ${ t('juror.longList')}` : t('juror.longList')}
              </button>
              <button
                className={`${
                  e.value === 'short-list' ? 'active' : ''
                } btn btn-lg positionBtn`}
                disabled={shortListDisabled}
                title={(shortList?.length && shortList.length >= 1) ? t('juror.error2') : ''}
                onClick={() =>
                  handleNominationChangeToggle(
                    e.cell.row.original.status, 'status', 'short-list', e.cell.row.original.status_history, nominationID
                  )
                }
              >
                {e.cell.row.original.status === 'short-list' ? `${t('juror.undo')} ${ t('juror.shortList')}` : t('juror.shortList')}
              </button>
            </div>
          );
        }
      }
    ],
    [auth]
  );

  useEffect(() => {
    (async () => {
      const nominations = await NominationControl.getJurorNominations();
      if (nominations) {
        setRegion(nominations.region);
        setVotingStatus(nominations.votingStatus);

        // Set all nominations
        const formatNominations = nominations.data.map((n) => ({
          ...n,
          artist: `${n.collective[0].artists[0].first_name} ${n.collective[0].artists[0].last_name}`
        }));
        setNominations(formatNominations);
      }

      const { settings } = await AdminControl.getSettings();
      if (settings) {
        // Check for the current region, and if there are list lock overrides
        let longLocked, shortLocked;
        if (nominations.region === 'Ontario') {
          longLocked = settings.lockLongOntario;
          shortLocked = settings.lockShortOntario;
        } else if (nominations.region === 'Quebec') {
          longLocked = settings.lockLongQuebec;
          shortLocked = settings.lockShortQuebec;
        } else if (nominations.region === 'Atlantic') {
          longLocked = settings.lockLongAtlantic;
          shortLocked = settings.lockShortAtlantic;
        } else if (nominations.region === 'Prairies & North') {
          longLocked = settings.lockLongPrairies;
          shortLocked = settings.lockShortPrairies;
        } else if (nominations.region === 'Prairies') {
          longLocked = settings.lockLongPrairies2;
          shortLocked = settings.lockShortPrairies2;
        } else if (nominations.region === 'North') {
          longLocked = settings.lockLongNorth;
          shortLocked = settings.lockShortNorth;
        } else if (nominations.region === 'Circumpolar') {
          longLocked = settings.lockLongCircumpolar;
          shortLocked = settings.lockShortCircumpolar;
        } else if (nominations.region === 'West Coast & Yukon') {
          longLocked = settings.lockLongWestCoast;
          shortLocked = settings.lockShortWestCoast;
        }

        if (longLocked) {
          setLongListOpen(false);
        } else {
          const longDifference = differenceInDays(
            new Date(settings.enableLongList),
            new Date(settings.currentDate)
          );
          if (longDifference > 0) {
            setLongListOpen(longDifference);
          } else {
            setLongListOpen(true);
          }
        }

        if (shortLocked) {
          setShortListOpen(false);
        } else {
          const shortDifference = differenceInDays(
            new Date(settings.enableShortList),
            new Date(settings.currentDate)
          );
          if (shortDifference > 0) {
            setShortListOpen(shortDifference);
          } else {
            setShortListOpen(true);
          }
        }
      }
    })();
  }, []);

  // Filter data for tabs + error check
  useEffect(() => {
    if (nominations) {
      // Considerations
      const filterConsiderations = nominations.filter(
        (n) => n.status === 'consider'
      );
      setConsiderations(filterConsiderations);

      // Long List
      const filterLongList = nominations.filter(
        (n) => n.status === 'long-list'
      );
      setLongList(filterLongList);

      // Short List
      const filterShortList = nominations.filter(
        (n) => n.status === 'short-list'
      );
      setShortList(filterShortList);

      // Error Checking
      if (filterLongList.length > 5) {
        setError(t('juror.error1'));
      } else if (filterShortList.length > 1) {
        setError(t('juror.error2'));
      } else if (tabIndex === 2 && filterLongList.length < 5) {
        const remainder = 5 - filterLongList.length;
        setError(<Trans i18nKey="juror.error3" values={{ remainder }} count={remainder} />);
      } else {
        setError(null);
      }
    }
  }, [nominations, tabIndex]);

  // Check if long list can be submitted
  useEffect(() => {
    if (votingStatus) {
      if (!error && votingStatus === VOTING_INPROGRESS) {
        setAllowSubmit(true);
      } else {
        setAllowSubmit(false);
      }
    }
  }, [error, votingStatus]);

  const handleNominationChange = async (
    key,
    value,
    nominationID,
  ) => {
    const updateNomination = await NominationControl.postPatchEntity(
      'PATCH',
      'submission',
      {
        [key]: value
      },
      nominationID
    );
    if (!updateNomination) return false;

    setNominations((nominations) => {
      const updatedNominations = nominations.map((n) => {
        return n.id === nominationID ? { ...n, [key]: value } : n;
      });
      return updatedNominations;
    });
  };

  const handleNominationChangeToggle = async (currentValue, key, matchValue, returnValue, nominationID) => {

    let options = {};

    if (currentValue == matchValue) {
        // to catch scenarios where there may not be a return value
        const restoreValue = returnValue || 'submitted';
        // we're already toggled, un-toggle
        options = {
          [`${key}_history`]: null,
          [key]: restoreValue,
        };
    } else {
      // we're not toggled, toggle
      options = {
        [`${key}_history`]: currentValue,
        [key]: matchValue,
      };
    }

    // Run operation
    const updateNomination = await NominationControl.postPatchEntity(
      'PATCH',
      'submission',
      options,
      nominationID
    );

    
    if (!updateNomination) return false;

    setNominations((nominations) => {
      const updatedNominations = nominations.map((n) => {
        return n.id === nominationID ? { ...n, ...options } : n;
      });
      return updatedNominations;
    });

  }


  const handleRowClick = (nominationID, columnID) => {
    if (['score', 'status'].includes(columnID)) return false;
    navigate(`${t('routes:jurorView')}/${nominationID}`);
  };

  return (
    <>
      {error &&
        <div id="Error-Bar" className={`${error ? 'active' : ''}`}><p>{error}</p></div>
      }
      <div className="container">
        <div className="row">
          <div className="col-12 pt-3 pb-5">
            {!nominations ? (
              <div>Loading...</div>
            ) : (
              <>
                <JurorConfirmModal
                  isOpen={modalOpen}
                  onClose={onModalToggle}
                  region={region}
                  nominees={longList}
                  submittedCallback={submittedCallback}
                />
                <h1 className="mb-5">{region} {t('nomination.step1.region.title')}</h1>
                <Tabs selectedIndex={tabIndex} onSelect={(i) => setTabIndex(i)} className="jurorTabs">
                  <TabList>
                    <Tab>
                      <span className="submissionCount">
                        {nominations.length}
                      </span>
                      <span className="tabTitle">{t('juror.regionalSubmissions')}</span>
                      <span className="daysLeft">{t('juror.votingOpen')}</span>
                    </Tab>
                    <Tab>
                      <span className="submissionCount">
                        {considerations?.length}
                      </span>
                      <span className="tabTitle">{t('juror.myConsideration')}</span>
                      <span className="daysLeft">{t('juror.votingOpen')}</span>
                    </Tab>
                    <Tab disabled={longListOpen !== true}>
                      <span className="submissionCount">
                        {longList?.length}/5
                      </span>
                      <span className="tabTitle">{t('juror.regionalLongList')}</span>
                      <span className="daysLeft">
                        {longListOpen === true
                          ? t('juror.votingOpen')
                          : longListOpen > 0
                          ? <Trans i18nKey="juror.opensIn" values={{ longListOpen }} count={longListOpen} />
                          : t('juror.votingLocked')
                        }
                      </span>
                    </Tab>
                    <Tab disabled={shortListOpen !== true}>
                      <span className="submissionCount">
                        {shortList?.length}/1
                      </span>
                      <span className="tabTitle">{t('juror.regionalShortList')}</span>
                      <span className="daysLeft">
                        {shortListOpen === true
                          ? t('juror.votingOpen')
                          : shortListOpen > 0
                          ? <Trans i18nKey="juror.opensInShort" values={{ shortListOpen }} count={shortListOpen} />
                          : t('juror.votingLocked')
                        }
                      </span>
                    </Tab>
                    {[
                      'regional-juror',
                    ].includes(auth?.role) && auth?.allRegionsOpenToJurors && (
                      <li className="react-tabs__tab nationalResults">
                        <Link to={t('routes:national-results')}>
                          <span className="tabTitle">{t('juror.nationalResults')}</span>
                        </Link>
                      </li>
                    )}
                  </TabList>

                  <TabPanel>
                    {(longList?.length && longList.length >= 5) ? <div className={'active'}><p>{t('juror.error1')}</p></div> : <></>}
                    {(shortList?.length && shortList.length >= 1) ? <div className={'active'}><p>{t('juror.error2')}</p></div> : <></>}
                    
                    <Table
                      columns={columns}
                      data={nominations}
                      longListOpen={longListOpen}
                      shortListOpen={shortListOpen}
                      longList={longList}
                      shortList={shortList}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Table
                      columns={columns}
                      data={considerations}
                      longListOpen={longListOpen}
                      shortListOpen={shortListOpen}
                      longList={longList}
                      shortList={shortList}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Table
                      columns={columns}
                      data={longList}
                      longListOpen={longListOpen}
                      shortListOpen={shortListOpen}
                      longList={longList}
                      shortList={shortList}
                    />
                    {votingStatus === VOTING_INPROGRESS ? (
                      <div id="Submitted">
                        <p>{t('juror.instructions1')}<br />
                          <strong>
                            {t('juror.instructions2')}
                          </strong>
                        </p>
                        <button
                          className="btn btn-lg mt-3 submit"
                          disabled={!allowSubmit}
                          onClick={onModalToggle}
                        >
                          {t('juror.submitNominees')}
                        </button>
                      </div>
                    ) : (
                      <div id="Submitted">
                        <img src={Check} alt="check" />
                        <span>{t('juror.submitted')}</span>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <Table
                      columns={columns}
                      data={shortList}
                      longListOpen={longListOpen}
                      shortListOpen={shortListOpen}
                      longList={longList}
                      shortList={shortList}
                    />
                  </TabPanel>
                </Tabs>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );

  function Table({ columns, data, longListOpen, shortListOpen, longList, shortList }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable(
      {
        columns,
        data,
        longListOpen,
        shortListOpen,
        longList,
        shortList,
        initialState: {
          sortBy: [
            {
              id: 'createdAt',
              desc: true
            }
          ]
        }
      },
      useSortBy
    );

    return (
      <table {...getTableProps()} className="jurorTable">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span className={`${column.isSorted ? 'active' : ''}`}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' ▼'
                        : ' ▲'
                      : !column.disableSortBy
                      ? ' ▲'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={row.cells[3].value}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClick={() =>
                          handleRowClick(cell.row.original.id, cell.column.id)
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4">{t('juror.noResults')}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
};

export default JurorDashboard;

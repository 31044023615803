import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import NominationHeader from './NominationHeader';
import NominationFooter from './NominationFooter';
import MediaUploadModal from '../../global/MediaUploadModal/MediaUploadModal'

const NominationStep4 = () => {
  const navigate = useNavigate();
  const [saved, setSaved] = useState(false);
  const [saveForLater, setSaveForLater] = useState(false);
  const handleSaveForLater = () => setSaveForLater(true);
  const { t, i18n } = useTranslation();
  const [mediaWorksData, setMediaWorksData] = useState({
    id: null,
    media: null
  });

  const dataCallback = (mediaWorksData) => {
    if (mediaWorksData) setMediaWorksData(mediaWorksData);
  };

  const { handleSubmit } = useForm();

  const onSubmit = data => {
    if (saveForLater) {
      setSaved(true);
      setSaveForLater(false);
    } else {
      navigate(`${t('routes:step5')}/${mediaWorksData.id}`);
    }
  };

  const [refresh, setRefresh] = useState(true);
  const updateFormState = () => {
    setRefresh(!refresh);
  }

  return (
    <>
      <div className="container">
        <NominationHeader step="4"  data={dataCallback} refresh={refresh}  />

        <form id="nomination-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row bg-light">
            <div className="col-12 col-lg-8 p-lg-4 pt-lg-5 pt-5">
              <label className="form-label d-xl-flex align-items-xl-center fw-bold">
                {t('nomination.step4.heading1')}
              </label>
              <MediaUploadModal
                  nominationId={mediaWorksData.id}
                  button={<button className="btn btn-light btn-outline mt-4 mb-5 d-block" type="button">
                  {/* <i
                    className="fa fa-plus"
                    style={{ marginRight: '10px' }}
                  ></i> */}
                  {t('nomination.step4.add')}
                </button>}
                media={mediaWorksData.media}
                callback={updateFormState}
              />
            </div>
            <div className="col-12 col-lg-4 pt-5">
              <div className="vstack">
                <label className="form-label d-xl-flex align-items-xl-center fw-bold mt-3">
                  {t('nomination.step4.heading2')}
                </label>
              </div>
              <ul className="small fw-light">
                {i18n.language === 'fr' ? (
                  <>
                    <li>
                      Se limiter à un maximum de cinq (5) pièces audio ou vidéo.
                    </li>
                    <li>
                      Les proposants doivent rendre accessibles les pièces audio ou vidéo en ligne (dans une zone sécurisée d’un site Web ou sur YouTube ou Vimeo);
                    </li>
                    <li>
                      Les fichiers ZIP ne seront pas acceptés.
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      No more than five (5) video or audio works will be accepted.
                    </li>
                    <li>
                      You are required to make video files available online (through a secure area of your website, or via YouTube or Vimeo).
                    </li>
                    <li>
                      ZIP files will not be accepted.
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </form>
      </div>

      <NominationFooter saved={saved} saveForLater={handleSaveForLater} />
    </>
  );
};

export default NominationStep4;

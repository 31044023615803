import React from 'react';

const DeleteButton = ({action}) => {
    
    const handleOnClick = async () => {
        return await action();
    }

    return (
        <button
            className="btn btn-light btn-sm text-end rounded-circle btn-close position-absolute top-0 end-0"
            type="button"
            data-bs-target="#modal-1"
            data-bs-toggle="modal"
            onClick={handleOnClick}
        ></button>
    )
}

export default DeleteButton;
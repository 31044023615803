import { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAuth } from '../../../utils/auth';
import { NominationControl } from '../../../utils/nomination';
import 'react-tabs/style/react-tabs.css';
import '../../../assets/scss/JurorDashboard.scss';

const NationalResults = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [nominationData, setNominationData] = useState(null);
  const [shortList, setShortList] = useState(null);
  const [winner, setWinner] = useState(null);
  const [considerations, setConsiderations] = useState(null);
  const [allSubmissions, setAllSubmissions] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: t('nomination.header.step1'),
        accessor: 'artist'
      },
      /*{
        Header: 'Notes',
        accessor: 'note',
        disableSortBy: true
      },
      {
        Header: "Chair's Notes",
        accessor: 'chairNote',
        disableSortBy: true
      }*/
    ],
    []
  );

  /*const handleNominationChange = async (key, value, nominationID, role) => {
    if (role !== 'international-juror') return false;

    const updateNomination = await NominationControl.postPatchEntity(
      'PATCH',
      'submission',
      {
        [key]: value
      },
      nominationID
    );
    if (!updateNomination) return false;

    setShortList((nominations) => {
      const updatedShortList = nominations.map((n) => {
        return n.id === nominationID ? { ...n, [key]: value } : n;
      });
      return updatedShortList;
    });
  };*/

  const handleRowClick = (nominationID, columnID) => {
    if (['score', 'status'].includes(columnID)) return false;
    navigate(`${t('routes:jurorView')}/${nominationID}`);
  };

  useEffect(() => {
    NominationControl.getNationalResults().then(
      ({ jurors, winner, shortList, nominations }) => {
        for (const [key, value] of Object.entries(nominations)) {
          nominations[key] = value.map((n) => ({
            ...n,
            artist: `${n.collective[0].artists[0].first_name} ${n.collective[0].artists[0].last_name}`
          }));
        }

        const formatShortList = shortList.map((n) => ({
          ...n,
          artist: `${n.collective[0].artists[0].first_name} ${n.collective[0].artists[0].last_name}`
        }));

        setShortList(formatShortList);
        setNominationData(nominations);

        if (winner) {
          setWinner([{
            ...winner,
            artist: `${winner.collective[0].artists[0].first_name} ${winner.collective[0].artists[0].last_name}`
          }]);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (auth?.role == 'regional-juror' && !auth?.allRegionsOpenToJurors) {
      // If we try to load National Results 
      // before National results is open to Jurors lets 
      navigate(`/juror-dashboard`);
    }
    if (auth.role === 'collaborator' || auth.role === 'international-juror' || auth.role === 'head-juror' || auth.role === 'admin') {
      NominationControl.getConsiderations().then(
        (considerations) => {
          const formatConsiderations = considerations.map((n) => ({
            ...n,
            artist: `${n.collective[0].artists[0].first_name} ${n.collective[0].artists[0].last_name}`
          }));

          setConsiderations(formatConsiderations);
        }
      );
    }
    if (auth.role === 'collaborator' || auth.role === 'head-juror' || auth.role === 'admin') {
      NominationControl.getAllSubmissions().then(
        (submissions) => {
          const formatSubmissions = submissions.map((n) => ({
            ...n,
            artist: `${n.collective[0].artists[0].first_name} ${n.collective[0].artists[0].last_name}`
          })).filter((f) => f.status != 'in progress');

          setAllSubmissions(formatSubmissions);
        }
      );
    }
  }, [auth.role]);

  /* if (allSubmissions) {
    const distinctValues = allSubmissions.reduce((acc, current) => {
      const value = current.region;
      if (!acc.includes(value)) {
        acc.push(value);
      }
      return acc;
    }, []);
    
    console.log(distinctValues, nominationData);
  }
 */
  return (
    <div id="National-Results" className="container">
      <div className="row">
        <div className="col-12 pt-3 pb-5">
          {!nominationData ? (
            <div>Loading...</div>
          ) : (
            <>
              <h1 className="mb-5">{t('juror.nationalResults')}</h1>
              <Tabs>
                <TabList className="react-tabs__tab-list nationalTabs">
                  <Tab>
                    <span className="tabTitle">{t('juror.allRegions')}</span>
                  </Tab>
                  {auth && (auth.role === 'collaborator' || auth.role === 'international-juror' || auth.role === 'head-juror' || auth.role === 'admin') && (
                    <Tab>
                      <span className="tabTitle">{t('juror.allConsiderations')}</span>
                    </Tab>
                  )}
                  {auth && (auth.role === 'admin' || auth.role === 'head-juror' || auth.role === 'collaborator') && (
                    <Tab>
                      <span className="tabTitle">{t('juror.allSubmissions')}</span>
                    </Tab>
                  )}
                  {Object.entries(nominationData).map(([key, value]) => (
                    <Tab key={key}>
                      <span className="tabTitle">{key}</span>
                    </Tab>
                  ))}
                  {auth && auth.role === 'regional-juror' && (
                    <li className="react-tabs__tab nationalResults">
                      <Link to={t('routes:juror-dashboard')}>
                        <span className="tabTitle">{t('juror.jurorDashboard')}</span>
                      </Link>
                    </li>
                  )}
                </TabList>
                <TabPanel>
                  {winner && winner.length ?
                    <div className="row">
                      <div className="col-12 pb-5">
                        <h2 style={{ marginTop: '0px' }}>
                          {t('juror.nationalWinner')}
                        </h2>
                        <Table
                          columns={columns}
                          data={winner}
                          short={true}
                        />
                      </div>
                    </div>
                  : <></>}
                  {shortList && shortList.length ?
                    <div className="row">
                      <div className="col-12 pb-5">
                        <h2 style={{ marginTop: '0px' }}>
                          {t('juror.nationalShortList')}
                        </h2>
                        <Table
                          columns={columns}
                          data={shortList}
                          short={true}
                        />
                      </div>
                    </div>
                  : <></>}
                  {nominationData && Object.keys(nominationData).length ?
                    <>
                      <h2>{t('juror.regionalNominees')}</h2>
                      {Object.entries(nominationData).map(([key, value]) => (
                        <div key={key} className="row">
                          <div className="col-12 pt-3 pb-5">
                            <h3 className="mb-4">{key}</h3>
                            <Table columns={columns} data={value.filter((n) => n.status === 'long-list')} />
                          </div>
                        </div>
                      ))}
                    </>
                  : <></>}
                </TabPanel>
                {auth && (auth.role === 'collaborator' || auth.role === 'international-juror' || auth.role === 'head-juror' || auth.role === 'admin') && (
                  <TabPanel>
                    {considerations &&
                      <div className="row">
                        <div className="col-12 pb-5">
                          <Table
                            columns={columns}
                            data={considerations}
                            short={true}
                          />
                        </div>
                      </div>
                    }
                  </TabPanel>
                )}
                {auth && (auth.role === 'admin' || auth.role === 'head-juror' || auth.role === 'collaborator') && (
                  <TabPanel>
                    {allSubmissions &&
                      <div className="row">
                        <div className="col-12 pb-5">
                          <Table
                            columns={columns}
                            data={allSubmissions}
                            showStatus={true}
                            short={true}
                          />
                        </div>
                      </div>
                    }
                  </TabPanel>
                )}
                {nominationData && Object.entries(nominationData).map(([key, value]) => (
                  <TabPanel key={key}>
                    <div className="row">
                      <div className="col-lg-8 pt-3 pb-5">
                        <h3 className="mb-4">{key} {t('juror.allSubmissions')}</h3>
                        <Table columns={columns} data={value.filter((n) => n.status !== 'long-list')} showStatus={true} />
                      </div>
                      <div className="col-lg-4 pt-3 pb-5">
                        <h3 className="mb-4">{key} {t('juror.longList')}</h3>
                        <Table columns={columns} data={value.filter((n) => n.status === 'long-list')} />
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
            </>
          )}
        </div>
      </div>
    </div>
  );

  function Table({ columns, data, short, showStatus = false }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable(
      {
        columns,
        data
      },
      useSortBy,
      hooks => {
        if (short) {
          hooks.visibleColumns.push(columns => [
            {
              Header: t('nomination.step1.region.title'),
              id: 'region',
              Cell: (e) => <span>{e.row.original.region}</span>
            },
            ...columns
            /*{
              Header: 'Rating',
              id: 'score',
              accessor: 'score',
              Cell: (e) => {
                const nominationID = e.row.original.id;
                const { score } = e.row.original;
                const { role } = e;

                if (role === 'international-juror') {
                  return (
                    <div className="rating" style={{ margin: 0 }}>
                      <span
                        className={`${score > 0 ? 'active' : ''} ratingCircle`}
                        onClick={() => handleNominationChange('score', 1, nominationID, role)}
                      ></span>
                      <span
                        className={`${score > 1 ? 'active' : ''} ratingCircle`}
                        onClick={() => handleNominationChange('score', 2, nominationID, role)}
                      ></span>
                      <span
                        className={`${score > 2 ? 'active' : ''} ratingCircle`}
                        onClick={() => handleNominationChange('score', 3, nominationID, role)}
                      ></span>
                      <span
                        className={`${score > 3 ? 'active' : ''} ratingCircle`}
                        onClick={() => handleNominationChange('score', 4, nominationID, role)}
                      ></span>
                      <span
                        className={`${score > 4 ? 'active' : ''} ratingCircle`}
                        onClick={() => handleNominationChange('score', 5, nominationID, role)}
                      ></span>
                    </div>
                  );
                } else {
                  return false;
                }
              }
            },
            {
              Header: 'Position',
              id: 'status',
              Cell: (e) => {
                const nominationID = e.row.original.id;
                const { status } = e.row.original;
                const { role } = e;

                if (role === 'international-juror') {
                  return (
                    <div className="position" style={{ justifyContent: 'flex-start' }}>
                      <button
                        className={`${status === 'dismiss' ? 'active' : ''} btn btn-lg positionBtn`}
                        onClick={() => handleNominationChange('status', 'dismiss', nominationID, status)}
                      >Dismiss</button>
                      <button
                        className={`${status === 'consider' ? 'active' : ''} btn btn-lg positionBtn`}
                        onClick={() => handleNominationChange('status', 'consider', nominationID, status)}
                      >Consider</button>
                      <button
                        className={`${status === 'long-list' ? 'active' : ''} btn btn-lg positionBtn`}
                        onClick={() => handleNominationChange('status', 'long-list', nominationID, status)}
                      >Long List</button>
                      <button
                        className={`${status === 'short-list' ? 'active' : ''} btn btn-lg positionBtn`}
                        onClick={() => handleNominationChange('status', 'short-list', nominationID, status)}
                      >Short List</button>
                    </div>
                  );
                } else {
                  return false;
                }
              }
            }*/
          ]);
        }   
        if (showStatus) {
          hooks.visibleColumns.push(columns => [
            ...columns,
            showStatus ? {
              Header: t('home.status'),
              id: 'status',
              Cell: (e) => <span>{e.row.original.status}</span>,
            } : {},
          ]);
        }
      }
    );

    return (
      <table {...getTableProps()}>
        { /* className={`${short ? 'short' : ''} ${auth?.role === 'international-juror' ? 'showExtra' : ''}`} */ }
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {showStatus ? <th></th> : null}
              {headerGroup.headers.map((column) => {
                if (typeof column.Header === 'function') return <th></th>;
                return (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span className={`${column.isSorted ? 'active' : ''}`}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' ▼'
                        : ' ▲'
                      : !column.disableSortBy
                      ? ' ▲'
                      : ''}
                  </span>
                </th>
              )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {showStatus ? <td>{i+1}.</td> : null}
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className={cell.column.id} onClick={() => handleRowClick(cell.row.original.id, cell.column.id)}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4">{t('juror.noResults')}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
};

export default NationalResults;

import React from 'react';
import Modal from 'react-modal';
import { Spinner, Alert, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      maxWidth: '100%',
      maxHeight: '96%',
      padding: '40px 20px'
    },
};

const UploadInProgressModal = ({uploadInProgress}) => {

    const { t } = useTranslation();

    return (
        <Modal
            isOpen={uploadInProgress}
            style={customStyles}
        >
            <div className="row">
                <div className="col-12 p-4 pb-0 text-center">
                    <h5 className="text-muted card-subtitle mb-2 fw-light mt-1 fw-bold small">
                    {t('nomination.components.uploading')}
                    </h5>
                </div>
                <div className="col-12 p-4 text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">{t('nomination.components.loading')}</span>
                    </Spinner>
                </div>
            </div>
        </Modal>
    )
}

export default UploadInProgressModal;
import React from 'react';
import FileUploadField from './FileUploadField';
import { NominationControl } from '../../utils/nomination';

const AttachmentUpload = ({ nominationId, field, accepts = ".jpg, .jpeg", button, callback, validateMe, setUploadInProgress }) => {

  const MINIMUM_FILE_SIZE_SIDE = 2000;
	const MAXIMUM_FILE_SIZE = 10000000; // 10mb
  
  const fileRef = React.useRef(null);

  const handleImageUpload = async (e) => {
    setUploadInProgress(true);
    const result = await NominationControl.uploadAndAttachNomination(e, nominationId, field);
    setUploadInProgress(false);
    return result;
  }

  const handleButtonPress = () => {
        fileRef.current.click();
  }

  const handleFileValidation = (file) => {
      if (!validateMe) return 1;
			
			if (file.size > MAXIMUM_FILE_SIZE) return 'size-error';
      
      return new Promise((resolve, reject) => {
            var _URL = window.URL || window.webkitURL;
            var img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            img.onload = function () {
                if (img.width < MINIMUM_FILE_SIZE_SIDE && img.height < MINIMUM_FILE_SIZE_SIDE) {
                    resolve('dimensions-error');
                } else {
                    resolve(1);
                }
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
      })
  }

  return (
    <div
        onClick={handleButtonPress}
    >
        {button}
        <FileUploadField
            ref={fileRef}
            onUploadEvent={handleImageUpload}
            accepts={accepts}
            callback={callback}
            onValidate={handleFileValidation}
        />
    </div>
  )
};

export default AttachmentUpload;

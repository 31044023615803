import { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  NavLink,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { Navbar, NavDropdown, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HomePage from './components/Pages/HomePage';
import AdminReport from './components/Pages/Admin/AdminReport';
import AdminUserManagement from './components/Pages/Admin/AdminUserManagement';
import AdminUserEdit from './components/Pages/Admin/AdminUserEdit';
import AdminUserCreate from './components/Pages/Admin/AdminUserCreate';
import AdminSettings from './components/Pages/Admin/AdminSettings';
import JurorDashboard from './components/Pages/Juror/JurorDashboard';
import NationalResults from './components/Pages/Juror/NationalResults';
import ViewNomination from './components/Pages/Juror/ViewNomination';
import LoginPage from './components/Pages/LoginPage';
import SecretLoginPage from './components/Pages/SecretLoginPage';
import RegisterPage from './components/Pages/RegisterPage';
import ForgotPasswordPage from './components/Pages/ForgotPasswordPage';
import NewPasswordPage from './components/Pages/NewPasswordPage';
import NominationStep1 from './components/Pages/Nomination/NominationStep1';
import NominationStep2 from './components/Pages/Nomination/NominationStep2';
import NominationStep3 from './components/Pages/Nomination/NominationStep3';
import NominationStep4 from './components/Pages/Nomination/NominationStep4';
import NominationStep5 from './components/Pages/Nomination/NominationStep5';
import NominationViewPage from './components/Pages/Nomination/NominationView';
import NominationClosedPage from './components/Pages/Nomination/NominationClosed';
import MySubmissions from './components/Pages/MySubmissions';
import {
  AuthProvider,
  RequireAuth,
  RequireJurorAuth,
  RequireAdminAuth,
  useAuth
} from './utils/auth';
import { AdminControl } from './utils/admin';
import Logo from './assets/img/logo.svg';
import LogoFR from './assets/img/logo-fr.svg';
import CanadaLogo from './assets/img/canada-logo.svg';
import './assets/scss/App.scss';
import './assets/scss/Media.scss';
import './assets/webfonts/font-awesome.min.css';

const App = () => {
  return (
    <AuthProvider>
      <ScrollToTop>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/admin/sign-in" element={<SecretLoginPage />} />
            <Route path="sign-in" element={<LoginPage />} />
            <Route path="sign-up" element={<RegisterPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="new-password" element={<NewPasswordPage />} />
            <Route path="connexion" element={<LoginPage />} />
            <Route path="inscrire" element={<RegisterPage />} />
            <Route
              path="mot-de-passe-oublie"
              element={<ForgotPasswordPage />}
            />
            <Route path="nouveau-mot-de-passe" element={<NewPasswordPage />} />
            <Route
              path="nomination-closed"
              element={<NominationClosedPage />}
            />
            <Route element={<RequireAuth />}>
              <Route path="/" element={<HomePage />} />
              <Route path="nomination">
                <Route path="step1/:id" element={<NominationStep1 />} />
                <Route path="step2/:id" element={<NominationStep2 />} />
                <Route path="step3/:id" element={<NominationStep3 />} />
                <Route path="step4/:id" element={<NominationStep4 />} />
                <Route path="step5/:id" element={<NominationStep5 />} />
                <Route path="view/:id" element={<NominationViewPage />} />
              </Route>
              <Route path="nomination">
                <Route path="etape1/:id" element={<NominationStep1 />} />
                <Route path="etape2/:id" element={<NominationStep2 />} />
                <Route path="etape3/:id" element={<NominationStep3 />} />
                <Route path="etape4/:id" element={<NominationStep4 />} />
                <Route path="etape5/:id" element={<NominationStep5 />} />
                <Route path="vue/:id" element={<NominationViewPage />} />
              </Route>
              <Route path="my-submissions" element={<MySubmissions />} />
            </Route>
            <Route element={<RequireJurorAuth />}>
              <Route path="juror-dashboard" element={<JurorDashboard />} />
              <Route path="national-results" element={<NationalResults />} />
              <Route path="view/:id" element={<ViewNomination />} />
            </Route>
            <Route element={<RequireAdminAuth />}>
              <Route path="admin-settings" element={<AdminSettings />} />
              <Route
                path="admin-user-management"
                element={<AdminUserManagement />}
              />
              <Route
                path="admin-user-management/edit/:id"
                element={<AdminUserEdit />}
              />
              <Route
                path="admin-user-management/create"
                element={<AdminUserCreate />}
              />
              <Route
                path="admin-submissions-report"
                element={<AdminReport />}
              />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </AuthProvider>
  );
};

const Layout = () => {
  const auth = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [nominationActive, setNominationActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { t, i18n } = useTranslation();

  // handle language + route changing
  const changeLanguageHandler = (lang) => {
    const currentLanguage = i18n.language === 'fr' ? 'fr' : 'en';
    const routes = i18n.getResourceBundle(currentLanguage, 'routes');
    const currentPathname = window.location.pathname.replace(/\/+$/, '');
    const currentRouteKey = Object.keys(routes).find(
      (key) => routes[key] === currentPathname
    );

    i18n.changeLanguage(lang);
    if (currentRouteKey)
      navigate({
        pathname: t(`routes:${currentRouteKey}`),
        search: window.location.search
      });
  };

  const [submissionDateMeta, setSubmissionDateMeta] = useState();
  useEffect(() => {
    const getSubmissionMetadata = async () => {
      const submissionsAreActive = await AdminControl.getSubmissionsAreActive();
      if (submissionsAreActive) {
        setSubmissionDateMeta(submissionsAreActive);
      }
    };
    getSubmissionMetadata();
  }, []);

  useEffect(() => {
    // log out nominators if the submissions are closed
    if (submissionDateMeta && !submissionDateMeta?.submissionsAreActive) {
      if (auth.role === 'artist') {
        auth.signOut();
        window.location.reload();
      }
    }
  }, [submissionDateMeta]);

  // toggle language based on domain
  useEffect(() => {
    if (window.location.hostname == 'nominationspsa.ca') {
      changeLanguageHandler('fr');
    }
    if (window.location.hostname == 'saanominations.ca') {
      changeLanguageHandler('en');
    }
  }, []);

  useEffect(() => {
    if (pathname.substring(0, 11) === '/nomination') {
      setNominationActive(true);
    } else {
      setNominationActive(false);
    }
  }, [pathname]);

  useEffect(() => {
    document.title =
      i18n.language === 'fr'
        ? 'Musée des beaux-arts du Canada - Prix Sobey pour les arts'
        : 'National Gallery of Canada - Sobey Art Award';
  }, [i18n?.language]);

  return (
    <div id="App">
      <Navbar
        className="sticky-top navigation-clean mb-4"
        bg="light"
        expand="lg"
        expanded={expanded}
      >
        <Container>
          <a
            className="navbar-brand"
            href={
              i18n.language === 'fr'
                ? 'https://www.beaux-arts.ca'
                : 'https://www.gallery.ca'
            }
          >
            {i18n.language === 'fr' ? (
              <img src={LogoFR} alt="National Gallery of Canada logo" />
            ) : (
              <img src={Logo} alt="National Gallery of Canada logo" />
            )}
          </a>
          <Navbar.Toggle
            onClick={() => setExpanded(!expanded)}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={
                    i18n.language === 'fr'
                      ? 'https://www.beaux-arts.ca'
                      : 'https://www.gallery.ca'
                  }
                >
                  {t('navigation.home')}
                </a>
              </li>

              {(auth?.user === 'guest' || auth?.role === 'artist') && (
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive || nominationActive
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    to="/"
                    onClick={() => setExpanded(false)}
                  >
                    {t('navigation.nomination')}
                  </NavLink>
                </li>
              )}

              {auth?.user !== 'guest' && (
                <li>
                  <NavDropdown title={t('navigation.account')}>
                    {auth?.role === 'admin' && (
                      <>
                        <NavLink
                          className="dropdown-item"
                          to="/admin-settings"
                          onClick={() => setExpanded(false)}
                        >
                          {t('navigation.adminSettings')}
                        </NavLink>
                        <NavLink
                          className="dropdown-item"
                          to="/admin-user-management"
                          onClick={() => setExpanded(false)}
                        >
                          {t('navigation.adminUserManagement')}
                        </NavLink>
                        <NavLink
                          className="dropdown-item"
                          to="/admin-submissions-report"
                          onClick={() => setExpanded(false)}
                        >
                          {t('navigation.submissionsReport')}
                        </NavLink>
                      </>
                    )}
                    {auth?.role === 'regional-juror' && (
                      <NavLink
                        className="dropdown-item"
                        to={t('routes:juror-dashboard')}
                        onClick={() => setExpanded(false)}
                      >
                        {t('juror.jurorDashboard')}
                      </NavLink>
                    )}
                    {[
                      'head-juror',
                      'international-juror',
                      'collaborator',
                      'admin'
                    ].includes(auth?.role) && (
                      <NavLink
                        className="dropdown-item"
                        to={t('routes:national-results')}
                        onClick={() => setExpanded(false)}
                      >
                        {t('juror.nationalResults')}
                      </NavLink>
                    )}
                    {[
                      'regional-juror',
                    ].includes(auth?.role) && auth?.allRegionsOpenToJurors && (
                      <NavLink
                        className="dropdown-item"
                        to={t('routes:national-results')}
                        onClick={() => setExpanded(false)}
                      >
                        {t('juror.nationalResults')}
                      </NavLink>
                    )}
                    <NavLink
                      className="dropdown-item"
                      to={t('routes:my-submissions')}
                      onClick={() => setExpanded(false)}
                    >
                      {t('home.mySubmissions')}
                    </NavLink>
                    <NavDropdown.Item
                      onClick={() => {
                        setExpanded(false);
                        auth.signOut();
                      }}
                    >
                      {t('navigation.logout')}
                    </NavDropdown.Item>
                    <NavLink
                      className="dropdown-item"
                      to={t('routes:forgot-password')}
                      onClick={() => setExpanded(false)}
                    >
                      {t('navigation.password')}
                    </NavLink>
                  </NavDropdown>
                </li>
              )}

              <li className="nav-item">
                {i18n.language === 'fr' ? (
                  <span
                    className="nav-link lang"
                    onClick={() => {
                      changeLanguageHandler('en');
                      setExpanded(false);
                    }}
                  >
                    EN
                  </span>
                ) : (
                  <span
                    className="nav-link lang"
                    onClick={() => {
                      changeLanguageHandler('fr');
                      setExpanded(false);
                    }}
                  >
                    FR
                  </span>
                )}
              </li>
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Outlet />

      <footer className="footer bg-primary">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-6 mb-4 mb-sm-0">
              <p className="mb-0">{t('footerCopyright')}</p>
            </div>
            <div className="col-12 col-sm-6 d-flex justify-content-end">
              <img
                src={CanadaLogo}
                alt="Canada Logo"
                style={{ maxWidth: '150px' }}
              />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

const NotFound = () => {
  const { t, i18n } = useTranslation();
  return <div class="App"><h3>{i18n.language === 'fr' ? 'La page que vous recherchez n\'existe pas.' : 'The page you are looking for does not exist.' }</h3></div>
};

export default App;

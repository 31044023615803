import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FloatingLabel, Alert } from 'react-bootstrap';
import { useAuth } from '../../utils/auth';
import { AdminControl } from '../../utils/admin';
import OtpPage from './OtpPage';

const LoginPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [urlParams] = useSearchParams();
  const [notice, setNotice] = useState(null);
  const [submissionDateMeta, setSubmissionDateMeta] = useState();
  const [requiresOTP, setRequiresOTP] = useState(false);
  const { t, i18n } = useTranslation();
  const confirmed = urlParams.get('confirmed');

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('errors.requiredEmail'))
      .email(t('errors.validEmail')),
    password: Yup.string()
      .required(t('errors.requiredPassword'))
      .min(8, t('errors.validPassword'))
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

  useEffect(() => {
    if (auth.role && auth.role === 'artist') navigate('/');
    else if (auth.role && auth.role === 'regional-juror') navigate(t('routes:juror-dashboard'));
    else if (auth.role && (auth.role === 'international-juror' || auth.role === 'head-juror' || auth.role === 'collaborator')) navigate(t('routes:national-results'));
    else if (auth.role && auth.role === 'admin') navigate('/admin-settings');
  }, [auth]);

  useEffect(() => {
    const getSubmissionMetadata = async () => {
      const submissionsAreActive = await AdminControl.getSubmissionsAreActive();
      if (submissionsAreActive) {
        setSubmissionDateMeta(submissionsAreActive);
      }
    }
    getSubmissionMetadata();
  }, [])

  useEffect(() => {
    if (confirmed)
      setNotice(
        <Alert variant="success">
          <p>{t('signIn.activatedSuccess')}</p>
        </Alert>
      );
  }, [confirmed, t]);

  const onSubmit = data => {
    auth.signIn(data.email, data.password, i18n.language, res => {
      if (res.error) {
        setNotice(<Alert variant="danger"><p>{res.error}</p></Alert>);
      } else {
        // check for OTP
        if (res.otpCode) {
          setRequiresOTP(res.email);
        } else {
          // Else Login
          if (res.role === 'artist') {
            navigate('/');
          } else if (res.role === 'regional-juror') {
            navigate(t('routes:juror-dashboard'));
          } else {
            navigate(t('routes:national-results'));
          }
        }
      }
    });
  };

  if (submissionDateMeta && !submissionDateMeta?.submissionsAreActive) {
    return <HoldingPage submissionDateMeta={submissionDateMeta} />
  }

  if (requiresOTP) {
    return <OtpPage email={requiresOTP} />
  }

  return (
    <div className="container">
      <div className="row g-3 justify-content-center">
    		<div className="col col-12 col-lg-4">
          {/** Using dangerouslySetInnerHTML here so that we properly register non-breaking spaces &nbsp; */}
          <h1 style={{marginBottom: 0}} dangerouslySetInnerHTML={{ __html: t('home.title')}} />
          {/** We keep this second header separate for security reasons, so that dynamic content from the DB isn't inserted into the DOM */}
          <h1 style={{margin: 0}}>{submissionDateMeta?.currentYear}</h1>
    			<h2>{t('signIn.title')}</h2>
    		</div>
    	</div>

      <div className="row g-3 mb-5 justify-content-md-center">
      	<div className="col col-12 col-lg-4">
          {notice}
          { errors.email &&
            <Alert variant="danger"><p>{errors.email.message}</p></Alert>
          }
          { errors.password &&
            <Alert variant="danger"><p>{errors.password.message}</p></Alert>
          }
          <form onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel
              controlId="email"
              label={t('forms.email')}
              className="mb-4"
            >
              <input
                className="form-control"
                type="email"
                autoComplete="email"
                placeholder="name@example.com"
                {...register('email')}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="password"
              label={t('forms.password')}
              className="mb-4"
            >
              <input
                className="form-control"
                type="password"
                autoComplete="current-password"
                placeholder="********"
                {...register('password')}
              />
  					</FloatingLabel>

            <button
              className="btn btn-lg"
              type="submit">
              {t('signIn.title')}
              <i className="fa fa-chevron-right ml-3"></i>
            </button>
          </form>
        </div>
      </div>

      <div className="row g-3 mb-5 justify-content-md-center">
        <div className="col col-12 col-lg-4 text-center">
          <Link to={t('routes:sign-up')}>{t('signUp.linkTitle')}</Link><br />
          <Link to={t('routes:forgot-password')}>{t('passwordReset.title1')}</Link>
        </div>
      </div>
    </div>
  );
}

const HoldingPage = ({submissionDateMeta}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <div className="row g-3 justify-content-center">
    		<div className="col col-12 col-lg-4">
          <h1 dangerouslySetInnerHTML={{ __html: t('home.title')}} />
    			<h2>{t('home.closedTitle')}</h2>
    		</div>
    	</div>

      <div className="row g-3 mb-5 justify-content-md-center">
      	<div className="col col-12 col-lg-4">
           <CountdownTimer submissionDateMeta={submissionDateMeta} />
        </div>
      </div>
    </div>
  )
}

const CountdownTimer = ({submissionDateMeta}) => {
  const { t, i18n } = useTranslation();
  const [startTime, setStartTime] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [onEnd, setOnEnd] = useState(false);
  const [onOver, setOnOver] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      
      if (startTime) {
          const timeRemaining = startTime.getTime() - new Date().getTime();
          const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

          setDays(days);
          setHours(hours);
          setMinutes(minutes);
          setSeconds(seconds);

          if (days < 0 && hours < 0 && minutes < 0 && seconds < 0) {
            setOnEnd(true);
          }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime]);

  useEffect(() => {
    setStartTime(new Date(submissionDateMeta?.startDate));
    setEndTime(new Date(submissionDateMeta?.endDate));

    const timeRemainingToEndDate = new Date(submissionDateMeta?.endDate).getTime() - new Date().getTime();
    if (timeRemainingToEndDate < 0) {
      setOnOver(true);
    }
  }, [submissionDateMeta])
  
  if (onOver) {
    return <p>{t('home.closedMessage')}</p>
  }
  if (onEnd) {
    return <p>{t('home.openMessage')}</p>
  }
  if (seconds) {
    return (
      <> {t('home.counterMessage')} <div className="countdown">
        <div>{days}<span>d</span></div>
        <div>{hours}<span>h</span></div>
        <div>{minutes}<span>m</span></div>
        <div>{seconds}<span>s</span></div>
        </div></>
    )
  }
  return null;
}

export default LoginPage;

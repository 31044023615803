import {
  SETTINGS_ENDPOINT,
  YEARS_ENDPOINT,
  SUBMISSIONS_ACTIVE_ENDPOINT,
  ENTITY_ENDPOINT,
  VALUES_ENDPOINT,
  ADMIN_CREATE_USER
} from './constants';

// Admin API calls
const AdminControl = {
  getSettings() {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    return fetch(SETTINGS_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getSubmissionsAreActive() {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    return fetch(SUBMISSIONS_ACTIVE_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getYears() {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    return fetch(YEARS_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  patchSettings(input) {
    const options = {
      method: 'PATCH',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(input)
    };

    return fetch(SETTINGS_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getEntity(entity, param = null, id = null) {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    const URL = `${ENTITY_ENDPOINT}/${entity}${param ? '/' + param : ''}${
      id ? '/' + id : ''
    }`;

    return fetch(URL, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  createUser(input) {
    const options = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(input)
    };

    return fetch(ADMIN_CREATE_USER, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  }
};

export { AdminControl };

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FloatingLabel, Alert } from 'react-bootstrap';
import { useAuth } from '../../utils/auth';
import { AdminControl } from '../../utils/admin';

const OtpPage = ({ email }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [urlParams] = useSearchParams();
  const [notice, setNotice] = useState(null);
  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    otpCode: Yup.string()
      .required(t('errors.requiredOTP'))
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

  useEffect(() => {
    if (auth.role && auth.role === 'artist') navigate('/');
    else if (auth.role && auth.role === 'regional-juror') navigate(t('routes:juror-dashboard'));
    else if (auth.role && (auth.role === 'international-juror' || auth.role === 'head-juror' || auth.role === 'collaborator')) navigate(t('routes:national-results'));
    else if (auth.role && auth.role === 'admin') navigate('/admin-settings');
  }, [auth]);

  const onSubmit = data => {
    auth.signInOTP(email, data.otpCode, i18n.language, res => {
      if (res.error) {
        setNotice(<Alert variant="danger"><p>{res.error}</p></Alert>);
      } else {
        if (res.message) {
            setNotice(<Alert variant="notice"><p>{res.message}</p></Alert>);
        }
        if (res.success) {
            // Else Login
            if (res.role === 'artist') {
                navigate('/');
            } else if (res.role === 'regional-juror') {
                navigate(t('routes:juror-dashboard'));
            } else {
                navigate(t('routes:national-results'));
            }
        }
      }
    });
  };

  return (
    <div className="container">
      <div className="row g-3 justify-content-center">
        <div className="col col-12 col-lg-5">
            <h1 dangerouslySetInnerHTML={{ __html: t('home.title')}} />
            <p>{t('signIn.otpTitle')}</p>
        </div>
    </div>

      <div className="row g-3 mb-5 justify-content-md-center">
      	<div className="col col-12 col-lg-5">
          {notice}
          { errors.otpCode &&
            <Alert variant="danger"><p>{errors.otpCode.message}</p></Alert>
          }
          <form onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel
              controlId="otpCode"
              label={t('forms.otpCode')}
              className="mb-4"
            >
              <input
                className="form-control"
                type="otpCode"
                autoComplete="otpCode"
                placeholder=""
                {...register('otpCode')}
              />
            </FloatingLabel>
            <button
              className="btn btn-lg"
              type="submit">
              {t('signIn.title')}
              <i className="fa fa-chevron-right ml-3"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default OtpPage;
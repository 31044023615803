import React from 'react';
import ImageUpload from './ImageUpload'
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NominationControl } from '../../../utils/nomination';
import { Alert, FloatingLabel, Spinner } from 'react-bootstrap';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../../global/images/DeleteButton';
import EditButton from '../../global/EditButton';
import ImageUploadCounter from '../../global/images/ImageUploadCounter';
import { handleApiErrors } from '../../../utils/error';

Modal.setAppElement('#root');

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      maxWidth: '100%',
      maxHeight: '96%',
      padding: '40px 20px'
    },
};

const ImageUploadModal = ({ nominationId, fields, accepts = ".jpg, .jpeg", button, callback, images, view }) => {

    const { t } = useTranslation();

    const MINIMUM_FILE_SIZE_SIDE = 2000;
		const MAXIMUM_FILE_SIZE = 10000000; // 10mb
    const MAXIMUM_IMAGE_COUNT = 10;
    const count = images ? images.length : 0;

    const [ imageAttachment, setImageAttachment ] = React.useState(null);
    const [ imageError, setImageError ] = React.useState(false);
    const [ apiError, setApiError] = React.useState(false);
    const [ removeError, setRemoveError] = React.useState(false);
    const [ modalOpen, setModalOpen ] = React.useState(false);
    const [ uploadInProgress, setUploadInProgress ] = React.useState(false);

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required()
            .label(t('nomination.components.title'))
            .max(250, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.components.title')).replace("{{length}}", 250))
            .nullable(),
        material: Yup.string()
            .required()
            .label(t('nomination.components.material'))
            .max(500, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.components.material')).replace("{{length}}", 500))
            .nullable(),
        year: Yup.string().required()
            .label(t('nomination.components.year'))
            .matches(/^[0-9]+$/, t('nomination.components.yearDigitsOnly'))
            .test('len', t('nomination.components.yearLength'), val => val.length === 4)
            .nullable(),
        dimensions: Yup.string()
            .required()
            .label(t('nomination.components.dimensions'))
            .max(40, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.components.dimensions')).replace("{{length}}", 60))
            .nullable(),
        credits: Yup.string()
            .required()
            .label(t('nomination.components.credits'))
            .max(250, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.components.credits')).replace("{{length}}", 250))
            .nullable(),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const {
        register,
        handleSubmit,
        formState: { errors, touchedFields },
        reset
    } = useForm(formOptions);

    const onSubmit = async (data) => {

        let imageAttachmentError = false;
        if (!imageAttachment) {
            setImageError(t('nomination.components.pleaseSelect'))
            imageAttachmentError = true;
        }

        setUploadInProgress(true);

        let result = false;
        if (!data.id) {

            const entity = {
                nominationId,
                ...data,
            }
            const fileInput = imageAttachment;

            result = await NominationControl.uploadAndAttachImage(fileInput, entity);
        } else {
            result = await NominationControl.postPatchSubmission('image', data, 'PATCH');
        }

        setUploadInProgress(false);

        const errors = handleApiErrors(result, setApiError);
        if (!errors && !imageAttachmentError) {
            setModalOpen(false);
            setImageError(false);
            setImageAttachment(null);
            callback();
            reset();
        }
    };

  const handleFile = (attachment) => {
        setImageAttachment(attachment);
  }

  const handleModalToggle = () => {
    setApiError(false);
    setRemoveError(false);
    setModalOpen(false);
    setImageAttachment(null);
    setImageError(false);
    reset({
        title: null,
        year: null,
        material: null,
        dimensions: null,
        credits: null,
        id: null
    });
    setModalOpen(true);
  }

  const handleModalClose = () => {
      setModalOpen(false);
      setImageError(false);
      setImageAttachment(null);
      reset();
      callback();
  }

  const handleFileValidation = (file) => {
    
    if (file.size > MAXIMUM_FILE_SIZE) {
        setImageError(t('nomination.components.sizeError'));
        return 'size-error';
    }
		
      return new Promise((resolve, reject) => {
            var _URL = window.URL || window.webkitURL;
            var img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            img.onload = function () {
                if (img.width < MINIMUM_FILE_SIZE_SIDE && img.height < MINIMUM_FILE_SIZE_SIDE) {
                    resolve('dimensions-error');
                    setImageError(t('nomination.components.dimensionError'));
                } else {
                    resolve(1);
                    setImageError(false);
                }
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
      })
  }

  const handleRemove = async (id) => {
    setRemoveError(false);
    let result = false;
    result = await NominationControl.postPatchEntity('DELETE', 'image',
    {},
    id)

    const errors = handleApiErrors(result, setRemoveError);
    if (!errors) {
        callback();
    }
  }

  const handleEdit = (entity) => {
    setRemoveError(false);
    setApiError(false);
    const year = entity.year ? entity.year.split("-")[0] : null;
    setImageAttachment({
        id: entity.id,
        editing: true,
    })
    reset({
        title: entity.title,
        year: year,
        material: entity.material,
        dimensions: entity.dimensions,
        credits: entity.credits,
        id: entity.id
    })
    setModalOpen(true);
  }

  return (
    <>
        {count < MAXIMUM_IMAGE_COUNT ? (
            <div
                onClick={handleModalToggle}
            >
                {button}
            </div>
        ) : null}
        {!view && <ImageUploadCounter images={images} /> }
        {removeError && (
                <div className="row bg-light">
                    <div className="col-12 p-4 pb-0">
                        <Alert key={`remove_Error`} variant="danger">
                            <p>{removeError}</p>
                        </Alert>
                    </div>
                </div>
        )}
        <div className="row">
        {images && images.length ? images.map((image) => (
            <div className="col col-6 col-md-4 p-2" key={image.fileId}>
                <div className="card">
                <div className="card-body bg-white">
                  {!view &&
                    <>
                      <DeleteButton
                          action={() => handleRemove(image.id)}
                      />
                      <EditButton
                          action={() => handleEdit(image)}
                      />
                    </>
                  }
                    {view ?
                      <Link to={image?.file?.path} target="_blank">
                        <img
                        className="card-img"
                        src={image?.file?.path}
                        alt=""
                        />
                      </Link>
                    :
                      <img
                      className="card-img"
                      src={image?.file?.path}
                      alt=""
                      />
                    }
                    <h5 className="text-muted card-subtitle mb-2 fw-light mt-1 fw-bold small">
                    {image.title}
                    </h5>
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                    {t('nomination.components.year')}: {image.year ? image.year.split("-")[0] : null}
                    </h6>
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                    {t('nomination.components.material')}: {image.material}
                    </h6>
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                    {t('nomination.components.dimensions')}: {image.dimensions}
                    </h6>
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1 small">
                    {t('nomination.components.credits')}: {image.credits}
                    </h6>
                </div>
                </div>
            </div>
            )) : null}
        </div>
        <Modal
            isOpen={modalOpen}
            onAfterOpen={() => null}
            onRequestClose={handleModalClose}
            style={customStyles}
        >
            <button
                className="btn btn-light btn-sm text-end rounded-circle btn-close position-absolute top-0 end-0"
                type="button"
                data-bs-target="#modal-1"
                data-bs-toggle="modal"
                onClick={handleModalClose}
            ></button>

            <div className="modal-header">
              <h3 className="modal-title mt-0">{t('nomination.step3.add')}</h3>
            </div>

                <form id="image-form">
                    {Object.keys(errors).length !== 0 && (
                        <div className="row bg-light">
                            <div className="col-12 p-4 pb-0">
                                {Object.keys(errors).map((field) => (
                                    <Alert key={field} variant="danger">
                                        <p>{errors[field].message}</p>
                                    </Alert>
                                ))}
                            </div>
                        </div>
                    )}
                    {imageError && (
                        <div className="row bg-light">
                            <div className="col-12 p-4 pb-0">
                                <Alert key={`image_error`} variant="danger">
                                    <p>{imageError}</p>
                                </Alert>
                            </div>
                        </div>
                    )}
                    {apiError && (
                        <div className="row bg-light">
                            <div className="col-12 p-4 pb-0">
                                {apiError.map((field) => (
                                    <Alert key={field} variant="danger">
                                        <p>{field}</p>
                                    </Alert>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="col-12 p-1 mt-4">
                        <div className="input-group">
                            <FloatingLabel controlId="title" label={t('nomination.components.title')}>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('nomination.components.title')}
                                    {...register('title')}
                                    maxLength="250"
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="input-group">
                            <FloatingLabel controlId="year" label={t('nomination.components.year')}>
                                <input
                                    className="form-control"
                                    type="text"
                                    pattern="\d*"
                                    maxLength="4"
                                    placeholder={t('nomination.components.year')}
                                    {...register('year')}
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="input-group">
                            <FloatingLabel controlId="material" label={t('nomination.components.material')}>
                                <textarea
                                  className="form-control"
                                  placeholder={t('nomination.components.material')}
                                  {...register('material')}
                                  maxLength="500"
                                  rows={4}
                                  style={{ height: 'auto' }}
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="input-group">
                            <FloatingLabel controlId="dimensions" label={t('nomination.components.dimensions')}>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('nomination.components.dimensions')}
                                    {...register('dimensions')}
                                    maxLength="40"
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <div className="input-group">
                            <FloatingLabel controlId="credits" label={t('nomination.components.credits')}>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('nomination.components.credits')}
                                    {...register('credits')}
                                    maxLength="250"
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    {imageAttachment?.editing !== true && (
                        <ImageUpload
                            nominationId={nominationId}
                            fields={fields}
                            accepts={accepts}
                            onComplete={handleFile}
                            onValidate={handleFileValidation}
                        />
                    )}
                    <div className="row">
                        <div className="col-6 p-1 pb-0 text-center">
                            {uploadInProgress && (
                                <>
                                    <h5 className="text-muted card-subtitle mb-2 fw-light mt-1 fw-bold small">
                                        {t('nomination.components.uploading')}
                                    </h5>
                                    <div className="text-center">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">{t('nomination.components.loading')}</span>
                                        </Spinner>
                                    </div>
                                </>
                            )}

                        </div>
                        <div className="col-6 p-1 text-right">
                            <button
                                className="btn btn-lg mb-3"
                                form="image-form"
                                onClick={handleSubmit(onSubmit)}
                            >
                            {t('nomination.components.saveImage')} <i className="fa fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </form>
        </Modal>
    </>
  )
};

export default ImageUploadModal;

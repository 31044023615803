import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GuidelinesModal from '../../global/GuidelinesModal';
import Download from '../../../assets/img/download.svg';

const NominationFooter = ({ saving, saved, saveForLater, final }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const onModalToggle = () => setModalOpen(!modalOpen);

  return (
    <>
      <GuidelinesModal isOpen={modalOpen} onClose={onModalToggle} />

      <footer className="actions mt-3">
        <div className="container">
          <div className="footer-btn-wrap d-flex">
            <button
              className="btn btn-lg btn-grey"
              style={{ marginRight: 'auto' }}
              onClick={onModalToggle}
            >
              {t('nomination.footer.guidelines')}
            </button>
            <div className="hstack gap-5 text-end d-flex flex-row justify-content-end">
              <div className="d-flex flex-row align-content-center justify-content-xl-center align-items-xl-center">
                { saving && !saved &&
                  <>
                    <img style={{ marginRight: '8px' }} src={Download} alt="" />
                    <h5 className="mb-0">{t('nomination.footer.saving')}</h5>
                  </>
                }
                { saved &&
                  <>
                    <i style={{ marginRight: '8px' }} className="fa fa-check d-xl-flex align-items-xl-center"></i>
                    <h5 className="mb-0">{t('nomination.footer.saved')}</h5>
                  </>
                }
              </div>
              { !final &&
                <button
                  className="btn btn-lg"
                  type="submit"
                  form="nomination-form"
                  onClick={saveForLater}
                >
                  {t('nomination.footer.saveLater')}
                </button>
              }
              <button
                className={`btn btn-lg ${final ? 'submit' : ''}`}
                type="submit"
                form="nomination-form"
              >
                { final ? t('passwordReset.submit') : t('nomination.footer.next') }<i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default NominationFooter

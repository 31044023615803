import React from 'react';

const EditButton = ({action}) => {
    
    const handleOnClick = async () => {
        return await action();
    }

    return (
        <button
            className="btn btn-light btn-sm text-end rounded-circle btn-close btn-edit position-absolute top-0 start-0"
            type="button"
            data-bs-target="#modal-1"
            data-bs-toggle="modal"
            onClick={handleOnClick}
        ></button>
    )
}

export default EditButton;
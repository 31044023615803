import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FloatingLabel, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import toast, { Toaster } from 'react-hot-toast';
import { AdminControl } from '../../../utils/admin';
import { useAuth } from '../../../utils/auth';

const AdminUserCreate = () => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [savingSettings, setSavingSettings] = useState('Create User');

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('nomination.step1.errors.firstName'))
      .max(
        30,
        t('errors.textfieldLength')
          .replace('{{field_name}}', t('nomination.step1.firstName'))
          .replace('{{length}}', 30)
      )
      .nullable(),
    lastName: Yup.string()
      .required(t('nomination.step1.errors.lastName'))
      .max(
        30,
        t('errors.textfieldLength')
          .replace('{{field_name}}', t('nomination.step1.lastName'))
          .replace('{{length}}', 30)
      )
      .nullable(),
    email: Yup.string()
      .required(t('errors.requiredEmail'))
      .email(t('errors.validEmail')),
    region: Yup.string().required('Region is required').nullable(),
    role: Yup.string().required('Role is required').nullable()
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    setSavingSettings('Creating...');
    data.isVerified = 1;

    const createUser = await AdminControl.createUser(data);

    if (createUser.success) {
      reset();
      toast.success('User successfully created');
      setSavingSettings('Created');

      // Send password reset
      auth.requestResetPassword(data.email, `admin`, (res) => {
        if (res.error) console.error(res.error);
      });
    } else {
      toast.error('There was an error with creating the user');
      setSavingSettings('Error');
    }

    setTimeout(() => setSavingSettings('Create User'), 2000);
  };

  return (
    <div id="Admin-User-Create" className="container">
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 3000
        }}
      />

      <div className="row">
        <div className="col-12 pt-3">
          <h1 className="mb-5">Create User</h1>
        </div>
      </div>

      {Object.keys(errors).length !== 0 && (
        <div className="row">
          <div className="col-12 pb-3">
            {Object.keys(errors).map((field) => (
              <Alert key={field} variant="danger">
                <p>{errors[field].message}</p>
              </Alert>
            ))}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12 pb-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group">
              <FloatingLabel
                controlId="firstName"
                label={`${t('nomination.step1.firstName')} *`}
                className="mr-1"
              >
                <input
                  className="form-control"
                  type="text"
                  placeholder={t('nomination.step1.firstName')}
                  maxLength="30"
                  {...register('firstName')}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="lastName"
                label={`${t('nomination.step1.lastName')} *`}
                className="mr-1"
              >
                <input
                  className="form-control"
                  type="text"
                  placeholder={t('nomination.step1.lastName')}
                  maxLength="30"
                  {...register('lastName')}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="email"
                label={`${t('forms.email')} *`}
                className="mb-4"
              >
                <input
                  className="form-control"
                  type="email"
                  placeholder="name@example.com"
                  {...register('email')}
                />
              </FloatingLabel>
            </div>

            <div className="input-group">
              <FloatingLabel
                controlId="region"
                label={`${t('nomination.step1.region.title')} *`}
                className="mr-1"
              >
                <select {...register('region')} className="form-select">
                  <option value={t('nomination.step1.region.option1')}>
                    {t('nomination.step1.region.option1')}
                  </option>
                  <option value={t('nomination.step1.region.option6')}>
                    {t('nomination.step1.region.option6')}
                  </option>
                  <option value={t('nomination.step1.region.option8')}>
                    {t('nomination.step1.region.option8')}
                  </option>
                  <option value={t('nomination.step1.region.option3')}>
                    {t('nomination.step1.region.option3')}
                  </option>
                  <option value={t('nomination.step1.region.option4')}>
                    {t('nomination.step1.region.option4')}
                  </option>
                  <option value={t('nomination.step1.region.option5')}>
                    {t('nomination.step1.region.option5')}
                  </option>
                </select>
              </FloatingLabel>

              <FloatingLabel controlId="role" label="Role">
                <select {...register('role')} className="form-select">
                  <option value="artist">Artist</option>
                  <option value="regional-juror">Regional Juror</option>
                  <option value="international-juror">
                    International Juror
                  </option>
                  <option value="collaborator">Collaborator</option>
                  <option value="head-juror">Head Juror</option>
                  <option value="admin">Admin</option>
                </select>
              </FloatingLabel>
            </div>

            <div className="mt-5">
              <button
                type="submit"
                className="btn btn-lg mr-1"
                disabled={
                  savingSettings === 'Creating...' ||
                  savingSettings === 'Created'
                }
              >
                {savingSettings}
              </button>
              <button
                className="btn btn-lg"
                onClick={() => navigate('/admin-user-management')}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminUserCreate;

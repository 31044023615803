import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import NominationHeader from './NominationHeader';
import NominationFooter from './NominationFooter';
import ImageUploadModal from '../../global/images/ImageUploadModal';

const NominationStep3 = () => {
  const navigate = useNavigate();
  const [saved, setSaved] = useState(false);
  const [saveForLater, setSaveForLater] = useState(false);
  const handleSaveForLater = () => setSaveForLater(true);
  const { t, i18n } = useTranslation();
  const [digitalImagesData, setDigitalImagesData] = useState({
    id: null,
    images: null
  });

  const dataCallback = useCallback(digitalImagesData => {
    if (digitalImagesData) setDigitalImagesData(digitalImagesData);
  }, []);

  const { handleSubmit } = useForm();

  const onSubmit = data => {
    if (saveForLater) {
      setSaved(true);
      setSaveForLater(false);
    } else {
      navigate(`${t('routes:step4')}/${digitalImagesData.id}`);
    }
  };

  const [refresh, setRefresh] = useState(true);
  const updateFormState = () => {
    setRefresh(!refresh);
  }

  return (
    <>
      <div className="container">
        <NominationHeader step="3" data={dataCallback} refresh={refresh} />

        <form id="nomination-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row bg-light">
            <div className="col-12 col-lg-8 p-lg-4 pt-lg-5 pt-5">
              <label className="form-label d-xl-flex align-items-xl-center fw-bold">
                {t('nomination.step3.heading1')}
              </label>
              <ImageUploadModal
                  nominationId={digitalImagesData.id}
                  images={digitalImagesData.images}
                  button={<button className="btn btn-light btn-outline mt-4 mb-5 d-block" type="button">
                  {t('nomination.step3.add')}
                </button>}
                callback={updateFormState}
              />
            </div>
            <div className="col-12 col-lg-4 pt-5">
              <div className="vstack">
                <label className="form-label d-xl-flex align-items-xl-center fw-bold mt-3">
                  {t('nomination.step3.heading2')}
                </label>
              </div>
              <ul className="small fw-light">
                {i18n.language === 'fr' ? (
                  <>
                    <li>
                      Un minimum de (5) images doivent être soumises.
                    </li>
                    <li>
                     Les images soumises doivent être grandes, de haute qualité et d’une résolution minimale dont le côté le plus long doit faire au moins 2000 pixels (prêtes pour la presse et l’impression).
                    </li>
                    <li>
                      Se limiter à un maximum de dix (10) images du travail le plus récent de l’artiste ou du collectif.
                    </li>
                    <li>
                      Les fichiers doivent être compatibles PC et MAC (.jpg seulement).
                    </li>
                    <li>
                    Ne pas ajouter de caractères spéciaux, symboles, guillemets, espaces, etc. dans le nom du fichier.
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      A minimum of five (5) digital images must be submitted.
                    </li>
                    <li>
                      Large, high-quality images, where the longest side of the image should be at least 2,000 pixels long (press- and print-ready).
                    </li>
                    <li>
                      No more than ten (10) images of the nominee's most recent work will be accepted.
                    </li>
                    <li>
                      Files must be PC- and Mac-compatible (.jpg only).
                    </li>
                    <li>
                      Do not include special characters, symbols, quotation marks, spaces, etc., in the file name.
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </form>
      </div>

      <NominationFooter saved={saved} saveForLater={handleSaveForLater} />
    </>
  );
};

export default NominationStep3;

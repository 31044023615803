import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FloatingLabel, Alert } from 'react-bootstrap';
import NominationHeader from './NominationHeader';
import NominationFooter from './NominationFooter';
import { NominationControl } from '../../../utils/nomination';
import { useAuth } from '../../../utils/auth';
import AttachmentUpload from '../../global/AttachmentUpload';
import ArtistCollective from '../../global/Artist/ArtistCollective';
import DeleteButton from '../../global/images/DeleteButton';
import UploadInProgressModal from '../../global/UploadInProgressModal';

const NominationStep1 = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [artistData, setArtistData] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [saveForLater, setSaveForLater] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t('nomination.step1.errors.firstName'))
      .max(30, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.firstName')).replace("{{length}}", 30))
      .nullable(),
    last_name: Yup.string()
      .required(t('nomination.step1.errors.lastName'))
      .max(30, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.lastName')).replace("{{length}}", 30))
      .nullable(),
    region: Yup.string()
      .required(t('nomination.step1.errors.region'))
      .nullable(),
    email: Yup.string()
      .required(t('errors.requiredEmail'))
      .email(t('errors.validEmail'))
      .max(120, t('errors.textfieldLength').replace("{{field_name}}", t('forms.email')).replace("{{length}}", 120))
      .nullable(),
    phone: Yup.string()
      .required(t('nomination.step1.errors.phone'))
      //.min(9, t('nomination.step1.errors.phoneValid'))
      //.max(20, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.phone')).replace("{{length}}", 20))
      .nullable(),
    /* alternate_phone: Yup.string()
      .min(9, t('nomination.step1.errors.phoneValid'))
      .max(20, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.phone2')).replace("{{length}}", 20))
      .nullable(), */
    address_1: Yup.string()
      .required(t('nomination.step1.errors.address'))
      .max(40, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.address')).replace("{{length}}", 40))
      .nullable(),
    address_2: Yup.string()
      .max(40, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.address2')).replace("{{length}}", 40))
      .nullable(),
    address_rr: Yup.string()
      .max(40, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.addressrr')).replace("{{length}}", 40))
      .nullable(),
    address_city: Yup.string()
      .required(t('nomination.step1.errors.city'))
      .max(30, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.city')).replace("{{length}}", 30))
      .nullable(),
    address_province: Yup.string()
      .required(t('nomination.step1.errors.province'))
      .nullable(),
    address_pc: Yup.string()
      .required(t('nomination.step1.errors.postalCode'))
      .max(7, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.postalCode')).replace("{{length}}", 7))
      .nullable(),
    collective_name: Yup.string()
      .max(255)
      .nullable(),
    headshotCredit: Yup.string()
      .test('len', t('nomination.step1.errors.credit'), (val) => {
        if (artistData?.headshot?.path) {
          return val?.length > 0 && val !== '' && val !== ' ';
        }
        return true;
      })
      .max(120, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.credit')).replace("{{length}}", 120))
      .nullable()
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm(formOptions);
  const { isValid } = useFormState({ control });

  const handleSaveForLater = () => {
    if (isValid) setSaveForLater(true);
  }

  const dataCallback = (artistData) => {
    if (artistData) {
      if (initialLoad) {
        artistData.data.email = artistData.data.email || auth?.user;
        artistData.data.collective_name = artistData.collective_name;
        reset(artistData.data);
        setInitialLoad(false);
      }
      setArtistData(artistData);
    }
  };

  const onSubmit = (data) => {
    setSaving(true);
    setSaved(false);

    NominationControl.postPatchSubmission('artist', data, 'PATCH')
      .then((res) => {
        setSaving(false);

        if (saveForLater) {
          setSaveForLater(false);
          setSaved(true);
        } else {
          navigate(`${t('routes:step2')}/${artistData.id}`);
        }
      })
      .catch((err) => {
        setSaving(false);
        console.error(err);
      });
  };

  const updateFormState = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <div className="container">
        <NominationHeader step="1" data={dataCallback} refresh={refresh} />

        <form id="nomination-form" onSubmit={handleSubmit(onSubmit)}>
          {Object.keys(errors).length !== 0 && (
            <div className="row bg-light">
              <div className="col-12 col-lg-6 p-4 pb-0 pt-5">
                {Object.keys(errors).map((field) => (
                  <Alert key={field} variant="danger">
                    <p>{errors[field].message}</p>
                  </Alert>
                ))}
              </div>
            </div>
          )}

          <div className="row bg-light">
            <div className="col-12 col-lg-8 p-lg-4 pt-lg-5 pt-5">
              <div className="vstack">
                <label className="form-label d-xl-flex align-items-xl-center fw-bold">
                  {t('nomination.step1.heading1')}
                </label>
              </div>
              <div className="input-group mt-2">
                <FloatingLabel
                  controlId="first_name"
                  label={`${t('nomination.step1.firstName')} *`}
                  className="mr-1"
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.firstName')}
                    maxLength="30"
                    {...register('first_name')}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="last_name"
                  label={`${t('nomination.step1.lastName')} *`}
                  className="mr-1"
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.lastName')}
                    maxLength="30"
                    {...register('last_name')}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="region"
                  label={`${t('nomination.step1.region.title')} *`}
                >
                  <select {...register('region')} className="form-select">
                    <option value="">
                      {t('nomination.step1.region.placeholder')}
                    </option>
                    <option value={t('nomination.step1.region.option8')}>
                      {t('nomination.step1.region.option8')}
                    </option>
                    <option value={t('nomination.step1.region.option1')}>
                      {t('nomination.step1.region.option1')}
                    </option>
                    <option value={t('nomination.step1.region.option6')}>
                      {t('nomination.step1.region.option6')}
                    </option>
                    {/* <option value={t('nomination.step1.region.option7')}>
                      {t('nomination.step1.region.option7')}
                    </option> */}
                    {/* <option value={t('nomination.step1.region.option2')}>
                      {t('nomination.step1.region.option2')}
                    </option> */}
                    <option value={t('nomination.step1.region.option3')}>
                      {t('nomination.step1.region.option3')}
                    </option>
                    <option value={t('nomination.step1.region.option4')}>
                      {t('nomination.step1.region.option4')}
                    </option>
                    <option value={t('nomination.step1.region.option5')}>
                      {t('nomination.step1.region.option5')}
                    </option>
                  </select>
                </FloatingLabel>
              </div>
              <div className="vstack">
                <label className="form-label d-xl-flex align-items-xl-center mt-5 fw-bold">
                  {t('nomination.step1.heading2')}
                </label>
              </div>
              <div className="input-group mt-2">
                <FloatingLabel
                  controlId="email"
                  label={`${t('forms.email')} *`}
                  className="mr-1"
                >
                  <input
                    className="form-control"
                    type="email"
                    autoComplete="email"
                    placeholder="name@example.com"
                    {...register('email')}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="phone"
                  label={`${t('nomination.step1.phone')} *`}
                  className="mr-1"
                >
                  <input
                    className="form-control"
                    type="tel"
                    placeholder={t('nomination.step1.phone')}
                    maxLength="20"
                    {...register('phone')}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="alternate_phone"
                  label={t('nomination.step1.phone2')}
                >
                  <input
                    className="form-control"
                    type="tel"
                    placeholder={t('nomination.step1.phone2')}
                    maxLength="20"
                    {...register('alternate_phone')}
                  />
                </FloatingLabel>
              </div>
              <div className="vstack">
                <label className="form-label d-xl-flex align-items-xl-center mt-5 fw-bold">
                  {t('nomination.step1.heading3')}
                </label>
                <p style={{ marginBottom: 0 }}>* {t('nomination.step1.canadianAddress')}</p>
              </div>
              <div className="input-group mt-2">
                <FloatingLabel
                  controlId="address_2"
                  label={t('nomination.step1.address2')}
                  className="mr-1"
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.address2')}
                    maxLength="40"
                    {...register('address_2')}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="address_1"
                  label={`${t('nomination.step1.address1')} *`}
                  className="mr-1"
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.address1')}
                    maxLength="40"
                    {...register('address_1')}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="address_rr"
                  label={t('nomination.step1.addressrr')}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.addressrr')}
                    maxLength="40"
                    {...register('address_rr')}
                  />
                </FloatingLabel>
              </div>
              <div className="input-group mt-md-4">
                <FloatingLabel
                  controlId="address_city"
                  label={`${t('nomination.step1.city')} *`}
                  className="mr-1"
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.city')}
                    maxLength="30"
                    {...register('address_city')}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="address_province"
                  label={`${t('nomination.step1.province')} *`}
                  className="mr-1"
                >
                  <select
                    {...register('address_province')}
                    className="form-select"
                  >
                    <option value="">{t('nomination.step1.province')}</option>
                    <option value="AB">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NU">Nunavut</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="YT">Yukon</option>
                  </select>
                </FloatingLabel>
                <FloatingLabel
                  controlId="address_pc"
                  label={`${t('nomination.step1.postalCode')} *`}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.postalCode')}
                    maxLength="7"
                    {...register('address_pc')}
                  />
                </FloatingLabel>
              </div>
              <label className="form-label d-xl-flex align-items-xl-center mt-5 fw-bold">
                {t('nomination.step1.heading4')}
              </label>
              <p className="fw-lighter">{t('nomination.step1.subheading1')}</p>
              <div className="input-group mt-md-4">
                <FloatingLabel
                    controlId="collective_name"
                    label={`${t('nomination.step1.collectiveName')}`}
                    className="mr-1"
                  >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.collectiveName')}
                    maxLength="255"
                    {...register('collective_name')}
                  />
                </FloatingLabel>
              </div>
              <ArtistCollective
                callback={updateFormState}
                collective={artistData?.collective}
                collectiveId={artistData?.collectiveId}
                button={
                  <button
                    className="btn btn-light btn-outline mt-4"
                    type="button"
                  >
                    {t('nomination.step1.add1')}
                  </button>
                }
              />
              <div className="vstack">
                <label className="form-label mt-5 fw-bold">
                  {artistData?.collective.length > 1 && i18n.language !== 'fr'
                    ? t('nomination.step1.collective')
                    : ''}{' '}
                  {t('nomination.step1.heading5')}{' '}
                  {artistData?.collective.length > 1 && i18n.language === 'fr'
                    ? t('nomination.step1.collective')
                    : ''}
                </label>
              </div>
              <p className="fw-lighter">
                {t('nomination.step1.subheading2')}{' '}
                {t('nomination.step1.subheading3')}
              </p>
              {artistData?.bio?.name && (
                <div className="card col-10 col-sm-6">
                  <div className="card-body bg-white">
                    <DeleteButton
                      action={async () => {
                        NominationControl.postPatchEntity(
                          'DELETE',
                          'file',
                          {},
                          artistData.biographyFileId
                        ).then(() => {
                          setRefresh(!refresh);
                        });
                      }}
                    />
                    <i className="fa fa-file-o text-center"></i>
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                      {artistData?.bio?.name}
                    </h6>
                  </div>
                </div>
              )}
              {!artistData?.bio && (
                <AttachmentUpload
                  nominationId={artistData?.id}
                  field="biographyFileId"
                  accepts=".pdf"
                  setUploadInProgress={setUploadInProgress}
                  button={
                    <button
                      className="btn btn-light btn-outline mt-4"
                      type="button"
                    >
                      {/* <i
                        className="fa fa-cloud-upload"
                        style={{ marginRight: '10px' }}
                      ></i> */}
                      {t('nomination.step1.add2')}
                    </button>
                  }
                  callback={updateFormState}
                />
              )}
              <div className="vstack">
                <label className="form-label mt-5 fw-bold">
                  {artistData?.collective.length > 1 && i18n.language !== 'fr'
                    ? t('nomination.step1.collective')
                    : ''}{' '}
                  {t('nomination.step1.heading6')}{' '}
                  {artistData?.collective.length > 1 && i18n.language === 'fr'
                    ? t('nomination.step1.collective')
                    : ''}
                </label>
              </div>
              <p className="fw-lighter">{t('nomination.step1.subheading3')}</p>
              {artistData?.cv?.name && (
                <div className="card col-10 col-sm-6">
                  <div className="card-body bg-white">
                    <DeleteButton
                      action={async () => {
                        NominationControl.postPatchEntity(
                          'DELETE',
                          'file',
                          {},
                          artistData.cvFileId
                        ).then(() => {
                          setRefresh(!refresh);
                        });
                      }}
                    />
                    <i className="fa fa-file-o text-center"></i>
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                      {artistData?.cv?.name}
                    </h6>
                  </div>
                </div>
              )}
              {!artistData?.cv && (
                <AttachmentUpload
                  nominationId={artistData?.id}
                  field="cvFileId"
                  accepts=".pdf"
                  setUploadInProgress={setUploadInProgress}
                  button={
                    <button
                      className="btn btn-light btn-outline mt-4"
                      type="button"
                    >
                      {/* <i
                      className="fa fa-cloud-upload"
                      style={{ marginRight: '10px' }}
                    ></i> */}
                      {t('nomination.step1.add3')}
                    </button>
                  }
                  callback={updateFormState}
                />
              )}
            </div>
            <div className="col-12 col-lg-4 pt-5">
              <div className="vstack">
                <label className="form-label d-xl-flex align-items-xl-center fw-bold mt-3">
                  {t('nomination.step1.heading7')}
                </label>
              </div>
              <p className="fw-lighter">{t('nomination.step1.subheading4')}</p>
              {artistData?.headshot?.path && (
                <div className="card w-100 mt-4">
                  <div className="card-body text-center bg-white">
                    <DeleteButton
                      action={async () => {
                        NominationControl.postPatchEntity(
                          'DELETE',
                          'file',
                          {},
                          artistData.headshotFileId
                        ).then(() => {
                          setRefresh(!refresh);
                        });
                      }}
                    />
                    <img src={artistData?.headshot?.path} alt="" />
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                      {artistData?.headshot?.name}
                    </h6>
                  </div>
                </div>
              )}
              {!artistData?.headshot && (
                <AttachmentUpload
                  nominationId={artistData?.id}
                  field="headshotFileId"
                  validateMe
                  setUploadInProgress={setUploadInProgress}
                  button={
                    <button
                      className="btn btn-light btn-outline mt-4"
                      type="button"
                    >
                      {/* <i
                        className="fa fa-cloud-upload"
                        style={{ marginRight: '10px' }}
                      ></i> */}
                      {t('nomination.step1.add4')}
                    </button>
                  }
                  callback={updateFormState}
                />
              )}
              {artistData?.headshot?.path && (
                <FloatingLabel
                  controlId="headshotCredit"
                  label={`${t('nomination.step1.credit')} *`}
                  className="mt-4"
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.credit')}
                    {...register('headshotCredit')}
                    maxLength="120"
                  />
                </FloatingLabel>
              )}
            </div>
          </div>
        </form>
      </div>

      <NominationFooter
        saving={saving}
        saved={saved}
        saveForLater={handleSaveForLater}
      />

      <UploadInProgressModal
        uploadInProgress={uploadInProgress}
      />
    </>
  );
};

export default NominationStep1;

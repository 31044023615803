import { useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FloatingLabel, Alert } from 'react-bootstrap';
import { useAuth } from '../../utils/auth';

const NewPasswordPage = () => {
  const auth = useAuth();
  const [urlParams] = useSearchParams();
  const token = urlParams.get('token');
  const email = urlParams.get('email');
  const [notice, setNotice] = useState(null);
  const [hideForm, setHideForm] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('errors.requiredPassword'))
      .min(8, t('errors.validPassword')),
    confirmPassword: Yup.string()
      .required(t('errors.requiredConfirmPassword'))
      .oneOf([Yup.ref('password')], t('errors.validConfirmPassword'))
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

  const onSubmit = data => {
    auth.changePassword({ email, password: data.password, token }, res => {
      if (res.message) {
        setNotice(<Alert variant="success"><p>{res.message} <Link to={t('routes:sign-in')}>{t('signIn.title')}</Link></p></Alert>);
        setHideForm(true);
      } else {
        setNotice(<Alert variant="danger"><p>{res.error}</p></Alert>);
      }
    });
  }

  return (
    <div className="container">
      <div className="row g-3 justify-content-md-center">
    		<div className="col col-12 col-lg-4">
          <h1 dangerouslySetInnerHTML={{ __html: t('home.title')}} />
    			<h2>{t('passwordReset.title3')}</h2>
    		</div>
    	</div>

    	<div className="row g-3 mb-5 justify-content-md-center">
    		<div className="col col-12 col-lg-4">
          {notice}
          { errors.password &&
            <Alert variant="danger"><p>{errors.password.message}</p></Alert>
          }
          { errors.confirmPassword &&
            <Alert variant="danger"><p>{errors.confirmPassword.message}</p></Alert>
          }
          { !hideForm &&
      		  <form onSubmit={handleSubmit(onSubmit)}>
              <FloatingLabel
                controlId="password"
                label={t('forms.passwordMin')}
                className="mb-4"
              >
                <input
                  className="form-control"
                  type="password"
                  placeholder="********"
                  {...register('password')}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="confirmPassword"
                label={t('forms.passwordConfirm')}
                className="mb-4"
              >
                <input
                  className="form-control"
                  type="password"
                  placeholder="********"
                  {...register('confirmPassword')}
                />
              </FloatingLabel>

              <button
                className="btn btn-lg"
                type="submit">
                {t('passwordReset.submit')}
                <i className="fa fa-chevron-right ml-3"></i>
              </button>
      		  </form>
          }
    		</div>
    	</div>

      <div className="row g-3 mb-5 justify-content-md-center">
        <div className="col col-12 col-lg-4 text-center">
          <Link to="/">{t('passwordReset.back')}</Link>
        </div>
      </div>
    </div>
  );
}

export default NewPasswordPage;

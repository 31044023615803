import { useTranslation } from 'react-i18next';

const NominationClosedPage = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="row">
    		<div className="col-12">
          <h1 dangerouslySetInnerHTML={{ __html: t('home.title')}} />
    		</div>
    	</div>

      <div className="row mb-5">
        <div className="col-12">
          <p className="fw-bold">{t('closed.p1')}</p>
          <p>{t('closed.p2')}</p>
        </div>
      </div>

    </div>
  );
}

export default NominationClosedPage;

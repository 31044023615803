// API
export const REGISTER_ENDPOINT = '/api/auth/register';
export const SIGNIN_ENDPOINT = '/api/auth/signIn';
export const SIGNIN_OTP_ENDPOINT = '/api/auth/signInOTP';
export const USER_ENDPOINT = '/api/auth/user';
export const LOGOUT_ENDPOINT = '/api/auth/logout';
export const REQUEST_RESET_PASSWORD_ENDPOINT = '/api/auth/requestResetPassword';
export const CHANGE_PASSWORD_ENDPOINT = '/api/auth/changePassword';
export const ADMIN_CREATE_USER = '/api/auth/createUser';
export const SUBMISSION_ENDPOINT = '/api/submission';
export const SUBMISSIONS_ENDPOINT = '/api/submissions';
export const SUBMISSION_EMAIL_ENDPOINT = '/api/submissionEmail';
export const ENTITY_ENDPOINT = '/api/entity';
export const UPLOAD_MEDIA_ENDPOINT = '/api/upload';
export const JUROR_DASHBOARD_ENDPOINT = '/api/juror/dashboard';
export const NATIONAL_RESULTS_ENDPOINT = '/api/juror/nationalResults';
export const NEXTPREV_SUBMISSIONS_ENDPOINT =
  '/api/juror/getNextPrevSubmissions';
export const SUBMIT_NOMINATIONS_ENDPOINT = '/api/juror/submitNominations';
export const CONSIDERATIONS_ENDPOINT = '/api/juror/getConsiderations';
export const ALLSUBMISSIONS_ENDPOINT = '/api/juror/getAllSubmissions';
export const ADMIN_REPORT_ENDPOINT = '/api/admin/report';
export const SETTINGS_ENDPOINT = '/api/admin/settings';
export const YEARS_ENDPOINT = '/api/admin/allYears';
export const SUBMISSIONS_ACTIVE_ENDPOINT = '/api/areSubmissionsActive';
export const GET_REGION_PERMISSIONS_ENDPOINT = '/api/areRegionalSubmissionsOpenToJurors';
export const SESSION_COOKIE_NAME = '_sid';

import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

const MediaUploadCounter = ({media}) => {

    const { t } = useTranslation();

    if (!Array.isArray(media)) return null;
    if (media.length < 1) return null;

    const MAXIMUM_MEDIA_COUNT = 5;
    const count = media.length;
    let message = t('nomination.components.maximum');
    let variant = 'success';

    if (count < MAXIMUM_MEDIA_COUNT) {
        const remainder = MAXIMUM_MEDIA_COUNT-count;
        message = <Trans i18nKey="nomination.components.upload" values={{ remainder }} count={remainder} />
        variant = 'warning';
    }

    return (
        <Alert key={`media_notice`} variant={variant}>
            <p>{message}</p>
        </Alert>
    )
}

export default MediaUploadCounter;

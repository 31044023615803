import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    maxWidth: '600px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '40px 20px'
  }
};

const ConfirmModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => null}
        onRequestClose={onClose}
        style={customStyles}
      >
        <button
          className="btn btn-light btn-sm btn-close position-absolute"
          type="button"
          onClick={onClose}
        ></button>
        <p>{t('nomination.step5.confirm')}</p>
        <button
          className="btn btn-lg"
          onClick={onClose}
        >
          {t('nomination.footer.saveLater')}
        </button>
        <button
          className="btn btn-lg submit"
          style={{ marginLeft: '2rem' }}
          type="submit"
          form="nomination-form"
        >
          {t('nomination.components.yes')}
        </button>
      </Modal>
    </>
  );
};

export default ConfirmModal;

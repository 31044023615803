import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FloatingLabel, Alert } from 'react-bootstrap';
import { useAuth } from '../../utils/auth';

const ForgotPasswordPage = () => {
  const auth = useAuth();
  const [notice, setNotice] = useState(null);
  const [hideForm, setHideForm] = useState(false);
  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('errors.requiredEmail'))
      .email(t('errors.validEmail'))
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

  const onSubmit = data => {
    auth.requestResetPassword(data.email, i18n.language, res => {
      if (res.message) {
        setNotice(<Alert variant="success"><p>{res.message}</p></Alert>);
        setHideForm(true);
      } else {
        setNotice(<Alert variant="danger"><p>{res.error}</p></Alert>);
      }
    });
  }

  return (
    <div className="container">
      <div className="row g-3 justify-content-center">
    		<div className="col col-12 col-lg-5">
          <h1 dangerouslySetInnerHTML={{ __html: t('home.title')}} />
    			<h2>{t('passwordReset.title2')}</h2>
    		</div>
    	</div>

    	<div className="row g-3 mb-5 justify-content-md-center">
    		<div className="col col-12 col-lg-5">
          {notice}
          { errors.email &&
            <Alert variant="danger"><p>{errors.email.message}</p></Alert>
          }
          { !hideForm &&
      		  <form onSubmit={handleSubmit(onSubmit)}>
              <FloatingLabel
                controlId="email"
                label={t('forms.email')}
                className="mb-4"
              >
                <input
                  className="form-control"
                  type="email"
                  autoComplete="email"
                  placeholder="name@example.com"
                  {...register('email')}
                />
              </FloatingLabel>

              <button
                className="btn btn-lg"
                type="submit">
                {t('passwordReset.reset')}
                <i className="fa fa-chevron-right ml-3"></i>
              </button>
      		  </form>
          }
    		</div>
    	</div>

      <div className="row g-3 mb-5 justify-content-md-center">
        <div className="col col-12 col-lg-4 text-center">
          <Link to="/">{t('passwordReset.back')}</Link><br />
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;

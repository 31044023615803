import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FloatingLabel, Alert } from 'react-bootstrap';
import NominationHeader from './NominationHeader';
import NominationFooter from './NominationFooter';
import { NominationControl } from '../../../utils/nomination';
import AttachmentUpload from '../../global/AttachmentUpload';
import DeleteButton from '../../global/images/DeleteButton';
import UploadInProgressModal from '../../global/UploadInProgressModal';

const NominationStep2 = () => {
  const navigate = useNavigate();
  const [nominatorData, setNominatorData] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [saveForLater, setSaveForLater] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    nominator_first_name: Yup.string()
      .required(t('nomination.step1.errors.firstName'))
      .max(120, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.firstName')).replace("{{length}}", 120))
      .nullable(),
    nominator_last_name: Yup.string()
      .required(t('nomination.step1.errors.lastName'))
      .max(120, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.lastName')).replace("{{length}}", 120))
      .nullable(),
    category: Yup.string()
      .required(t('nomination.step2.errors.category'))
      .nullable(),
    category_organization: Yup.string()
    .max(120, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step2.category.placeholder')).replace("{{length}}", 120))
    .nullable(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors }
  } = useForm(formOptions);

  const { isValid } = useFormState({ control });
  const category = watch('category');

  const handleSaveForLater = () => {
    if (isValid) setSaveForLater(true);
  }

  const dataCallback = (nominatorData) => {
    if (nominatorData) {
      if (initialLoad) {
        reset(nominatorData);
        setInitialLoad(false);
      }
      setNominatorData(nominatorData);
    }
  };

  const onSubmit = (data) => {
    setSaving(true);
    setSaved(false);

    const formatData = {
      id: nominatorData.id,
      nominator_first_name: data.nominator_first_name,
      nominator_last_name: data.nominator_last_name,
      category: data.category,
      category_organization: data.category_organization
    };

    NominationControl.postPatchSubmission('submission', formatData, 'PATCH')
      .then((res) => {
        setSaving(false);

        if (saveForLater) {
          setSaveForLater(false);
          setSaved(true);
        } else {
          navigate(`${t('routes:step3')}/${nominatorData.id}`);
        }
      })
      .catch((err) => {
        setSaving(false);
        console.error(err);
      });
  };

  const [refresh, setRefresh] = useState(true);
  const updateFormState = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <div className="container">
        <NominationHeader step="2" data={dataCallback} refresh={refresh} />

        <form id="nomination-form" onSubmit={handleSubmit(onSubmit)}>
          {Object.keys(errors).length !== 0 && (
            <div className="row bg-light">
              <div className="col-12 col-lg-6 p-4 pb-0 pt-5">
                {Object.keys(errors).map((field) => (
                  <Alert key={field} variant="danger">
                    <p>{errors[field].message}</p>
                  </Alert>
                ))}
              </div>
            </div>
          )}

          <div className="row bg-light">
            <div className="col-12 col-lg-8 p-lg-4 pt-lg-5 pt-5">
              <div className="vstack">
                <label className="form-label d-xl-flex align-items-xl-center fw-bold">
                  {t('nomination.step2.heading1')}
                </label>
              </div>
              <div className="input-group mt-2">
                <FloatingLabel
                  controlId="first_name"
                  label={`${t('nomination.step1.firstName')} *`}
                  className="mr-1"
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.firstName')}
                    maxLength="30"
                    {...register('nominator_first_name')}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="last_name"
                  label={`${t('nomination.step1.lastName')} *`}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('nomination.step1.lastName')}
                    maxLength="30"
                    {...register('nominator_last_name')}
                  />
                </FloatingLabel>
              </div>
              <div className="vstack">
                <label className="form-label mt-5 fw-bold">
                  {t('nomination.step2.heading2')}
                </label>
              </div>
              <p className="fw-lighter">
                {t('nomination.step2.nominatorsLetter')} {t('nomination.step1.subheading3')}
              </p>
              {nominatorData?.letter?.name && (
                <div className="card col-10 col-sm-6">
                  <div className="card-body bg-white">
                    <DeleteButton
                      action={async () => {
                        NominationControl.postPatchEntity(
                          'PATCH',
                          'submission',
                          {
                            letterFileId: null
                          },
                          nominatorData.id
                        ).then(() => {
                          setRefresh(!refresh);
                        });
                      }}
                    />
                    <i className="fa fa-file-o text-center"></i>
                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                      {nominatorData.letter.name}
                    </h6>
                  </div>
                </div>
              )}

              {!nominatorData?.letter && (
                <AttachmentUpload
                  nominationId={nominatorData?.id}
                  field="letterFileId"
                  accepts=".pdf"
                  setUploadInProgress={setUploadInProgress}
                  button={
                    <button
                      className="btn btn-light btn-outline mt-4"
                      type="button"
                    >
                      {t('nomination.step2.add')}
                    </button>
                  }
                  callback={updateFormState}
                />
              )}
            </div>
            <div className="col-12 col-lg-4 pt-5">
              <div className="vstack">
                <label className="form-label d-xl-flex align-items-xl-center fw-bold mt-3">
                  {t('nomination.step2.heading3')} *
                </label>
              </div>
              <div className="form-check mt-2 fw-light small">
                <input
                  {...register('category')}
                  className="form-check-input"
                  type="radio"
                  value={t('nomination.step2.category.option1')}
                />
                <label className="form-check-label">
                  {t('nomination.step2.category.option1')}
                </label>
              </div>
              <div className="form-check mt-3 fw-light small">
                <input
                  {...register('category')}
                  className="form-check-input"
                  type="radio"
                  value={t('nomination.step2.category.option2')}
                />
                <label className="form-check-label">
                  {t('nomination.step2.category.option2')}
                </label>
              </div>
              <div className="form-check mt-3 fw-light small">
                <input
                  {...register('category')}
                  className="form-check-input"
                  type="radio"
                  value="private"
                />
                <label className="form-check-label">
                  {t('nomination.step2.category.option3')}
                </label>

                {category !== null &&
                  category !== t('nomination.step2.category.option4') &&
                  category !== t('nomination.step2.category.option5') && (
                    <FloatingLabel
                      className="mt-2"
                      label={t('nomination.step2.category.placeholder')}
                    >
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t('nomination.step2.category.placeholder')}
                        maxLength="60"
                        {...register('category_organization')}
                      />
                    </FloatingLabel>
                  )}
              </div>

              <div className="form-check mt-3 fw-light small">
                <input
                  {...register('category')}
                  className="form-check-input"
                  type="radio"
                  value={t('nomination.step2.category.option4')}
                />
                <label className="form-check-label">
                  {t('nomination.step2.category.option4')}
                </label>
              </div>
              <div className="form-check mt-3 fw-light small">
                <input
                  {...register('category')}
                  className="form-check-input"
                  type="radio"
                  value={t('nomination.step2.category.option5')}
                />
                <label className="form-check-label">
                  {t('nomination.step2.category.option5')}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>

      <NominationFooter
        saving={saving}
        saved={saved}
        saveForLater={handleSaveForLater}
      />

      <UploadInProgressModal
        uploadInProgress={uploadInProgress}
      />
    </>
  );
};

export default NominationStep2;

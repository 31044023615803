import { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';
import ConfirmDeleteModal from '../global/ConfirmDeleteModal';
import { useAuth } from '../../utils/auth';
import { NominationControl } from '../../utils/nomination';
import { AdminControl } from '../../utils/admin';

const MySubmissions = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deleteID, setDeleteID] = useState(null);
  const [submissionsData, setSubmissionsData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const onModalClose = () => setModalOpen(false);
  const [submissionDateMeta, setSubmissionDateMeta] = useState();

  const columns = useMemo(
    () => [
      {
        Header: 'Region',
        accessor: 'region'
      },
      {
        Header: t('nomination.header.step1'),
        accessor: 'artist'
      },
      {
        Header: t('home.status'),
        accessor: 'status',
        Cell: (e) => {
          const nominationStatus = e.cell.row.original.status;
          // const submissionYear = new Date(e.cell.row.original.year).getFullYear();
          // const currentActiveYear = new Date(submissionsData?.endDate).getFullYear();

          // if ( submissionYear !== currentActiveYear ) {
          //  return 'Closed';
          // }
          let status_string = '';
          if (nominationStatus === 'in progress') {
            status_string = t('home.in_progress');
          }
          if (nominationStatus === 'long-list') {
            status_string = t('home.submitted');
          }
          if (nominationStatus === 'submitted') {
            status_string = t('home.submitted');
          }
          if (nominationStatus === 'dismiss') {
            status_string = t('home.submitted');
          }
          if (nominationStatus === 'consider') {
            status_string = t('home.submitted');
          }
          if (nominationStatus === 'short_list') {
            status_string = t('home.submitted');
          }
          if (nominationStatus === 'winner') {
            status_string = t('home.submitted');
          }

          return status_string;
        }
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        Cell: (e) => {
          const nominationID = e.cell.row.original.id;
          const nominationStatus = e.cell.row.original.status;
          const submissions = e?.data;

          return (
            <div className="position">
              <button
                className={`${
                  nominationStatus !== 'in progress' ? 'disabled' : ''
                } btn btn-lg positionBtn edit-submission`}
                onClick={() => navigate(`${t('routes:step1')}/${nominationID}`)}
              >
                {t('home.edit')}
              </button>
              <button
                className="btn btn-lg positionBtn"
                onClick={(e) => {
                  e.stopPropagation();
                  handleConfirmDelete(nominationID);
                  //handleDeleteSubmission(nominationID, submissions);
                }}
              >
                {t('home.delete')}
              </button>
            </div>
          );
        }
      }
    ],
    [t]
  );

  const handleNewSubmission = () => {
    NominationControl.createSubmission().then((submission) => {
      if (submission.id) navigate(`${t('routes:step1')}/${submission.id}`);
    });
  };

  const handleConfirmDelete = (id) => {
    setModalOpen(true);
    setDeleteID(id);
  };

  const handleDeleteSubmission = async () => {
    const submission = submissionsData.find((s) => s.id === deleteID);

    await Promise.all(
      submission.images.map(async (i) => {
        return await NominationControl.deleteEntity('image', i.id);
      })
    );
    await Promise.all(
      submission.mediaWork.map(async (m) => {
        return await NominationControl.deleteEntity('media', m.id);
      })
    );
    await Promise.all(
      submission.collective[0].artists.map(async (artist) => {
        return await NominationControl.deleteEntity('artist', artist.id);
      })
    );

    if (submission.biographyFileId)
      await NominationControl.deleteEntity('file', submission.biographyFileId);
    if (submission.cvFileId)
      await NominationControl.deleteEntity('file', submission.cvFileId);
    if (submission.headshotFileId)
      await NominationControl.deleteEntity('file', submission.headshotFileId);
    if (submission.letterFileId)
      await NominationControl.deleteEntity('file', submission.letterFileId);

    await NominationControl.deleteEntity(
      'collective',
      submission.collective[0].id
    );
    await NominationControl.deleteEntity('submission', deleteID);

    setModalOpen(false);
    setSubmissionsData(submissionsData.filter((s) => s.id !== deleteID));
  };

  useEffect(() => {
    (async () => {
      // first get submission metadata
      const submissionsAreActive = await AdminControl.getSubmissionsAreActive();
      if (submissionsAreActive) {
        setSubmissionDateMeta(submissionsAreActive);
      }

      // now get submissions
      const { submissions } = await NominationControl.getSubmissions();

      if (submissions.length) {
        let formatSubmissions = submissions.map((n) => {
          let validRegion = n.collective[0].artists.filter((a) => a.region);
          validRegion = validRegion[0] && validRegion[0]?.region || '';
          return {
            ...n,
            artist: n.collective[0].artists[0].first_name ? `${n.collective[0].artists[0].first_name} ${n.collective[0].artists[0].last_name}` : '',
            region: validRegion
          }
        });

        formatSubmissions = formatSubmissions.filter((submission) => {
          const submissionYear = new Date(submission?.year).getUTCFullYear();
          if (submissionYear === submissionsAreActive?.currentYear) {
            return true;
          }
          return false;
        });
        setSubmissionsData(formatSubmissions);
      } else {
        navigate('/');
      }
    })();
  }, []);

  return (
    <>
      <ConfirmDeleteModal
        isOpen={modalOpen}
        onClose={onModalClose}
        onSubmit={handleDeleteSubmission}
        type="submission"
      />

      <div
        className={`container ${
          (submissionDateMeta && submissionDateMeta?.submissionsAreActive) || auth.role !== 'artist'
            ? 'submissions-are-active'
            : 'submissions-are-closed'
        }`}
      >
        <div className="row">
          <div className="col-12 pb-3">
            <h1 className="mb-5" dangerouslySetInnerHTML={{ __html: `${t('home.title')} - ${t('home.mySubmissions')}`}} />
            {auth.role !== 'artist' || (submissionDateMeta && submissionDateMeta?.submissionsAreActive) ? (
              <button className="btn btn-lg" onClick={handleNewSubmission}>
                {t('home.addNew')}
              </button>
            ) : null}
          </div>
          <div className="col-12 pb-5">
            {!submissionsData ? (
              <p>Loading...</p>
            ) : (
              <Table columns={columns} data={submissionsData} />
            )}
          </div>
        </div>
      </div>
    </>
  );

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data
        },
        useSortBy
      );

    return (
      <table {...getTableProps()} className="mySubmissions">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span className={`${column.isSorted ? 'active' : ''}`}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' ▼'
                        : ' ▲'
                      : !column.disableSortBy
                      ? ' ▲'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4">{t('juror.noResults')}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
};

export default MySubmissions;

import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

const ImageUploadCounter = ({images}) => {

    const { t } = useTranslation();

    if (!Array.isArray(images)) return null;
    if (images.length < 1) return null;

    const MINIMUM_IMAGE_COUNT = 5;
    const MAXIMUM_IMAGE_COUNT = 10;
    const count = images.length;
    let message = t('nomination.components.maximumImage');
    let variant = 'success';

    if (count < MINIMUM_IMAGE_COUNT) {
        const remainder = MINIMUM_IMAGE_COUNT-count;
        message = <Trans i18nKey="nomination.components.stillUpload" values={{ remainder }} count={remainder} />
        variant = 'danger';
    }

    if (count >= MINIMUM_IMAGE_COUNT && count < MAXIMUM_IMAGE_COUNT) {
        const remainder = MAXIMUM_IMAGE_COUNT-count;
        message = <Trans i18nKey="nomination.components.canUpload" values={{ remainder }} count={remainder} />
        variant = 'warning';
    }

    return (
        <Alert key={`image_notice`} variant={variant}>
            <p>{message}</p>
        </Alert>
    )
}

export default ImageUploadCounter;

import React from 'react';
import { Spinner, Alert, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const FileUploadField = React.forwardRef(({ onUploadEvent, accepts = ".jpg, .jpeg", callback = () => null, onValidate = () => true, ignoreError, ignoreSuccess}, ref) => {

  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleFileSubmit = async (e) => {

    const verifyType = await onVerifyType(e);
    if (!verifyType) {
      if (accepts === ".jpg, .jpeg") {
        setError(t('nomination.components.filetypeJPGError'))
      }
      if (accepts === ".pdf") {
        setError(t('nomination.components.filetypePDFError'))
      }
    }
    const valid = await onValidate(e);

    if (valid === 1 && verifyType) {
        setError(false);
        setSuccess(false);
        setLoading(true);
        let error = false;
        try {
          const result = await onUploadEvent(e);
          if (!result?.file?.attachment?.id) {
            error = 1;
          }
        } catch(e) {
          console.warn(e);
          error = 1;
        }

        if (error) {
            setError(t('nomination.components.uploadError'));
        } else {
            if (!ignoreSuccess) {
              setSuccess(true);
            }
            callback();
        }

        setLoading(false);
    } else {
      if (!ignoreError) {
        if (valid === 'dimensions-error') {
          setError(t('nomination.components.dimensionError'));
        } else if (valid === 'size-error') {
					setError(t('nomination.components.sizeError'));
				}
      }
    }
  }

  const onVerifyType = (e) => {
    if (accepts === ".jpg, .jpeg") {
       return e.type === "image/jpeg";
    }
    if (accepts === ".pdf") {
      return e.type === "application/pdf";
    }
    return true;
  }

  /*React.useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 2000);
    }
  }, [success])*/

  return (
    <>
        <div style={{display: `none`}}>
          <input
              ref={ref}
              type="file"
              onChange={(e) => {
                handleFileSubmit(e.target.files[0]);
                e.target.value = null;
              }}
              accept={accepts}
              name="file-attachments-ul"
          />
        </div>
        {loading && (
          <div className="row bg-light">
            <div className="col-12 p-4 pb-0">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t('nomination.components.loading')}</span>
              </Spinner>
            </div>
          </div>
        )}
        {success && (
          <div className="row bg-light">
              <div className="col-12 p-2 pt-2 pb-0">
                <Badge pill bg="success">
                  {t('nomination.components.imageUploaded')}
                </Badge>
              </div>
          </div>
        )}
        {error && (
            <div className="row bg-light">
                <div className="col-12 p-4 pb-0">
                    <Alert key={`image_error`} variant="danger">
                        <p>{error}</p>
                    </Alert>
                </div>
            </div>
        )}
    </>
  );
});

export default FileUploadField;
